import { array, date, number, object, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import {
  COUNTERAGENTS,
  CURRENT_EMPLOYEES_AUTOCOMPLETE
} from 'src/config/avlUrls';
import { KPI_PARAMETERS_GROUPS } from 'src/config/clearingUrls';
import moment from 'moment';

export const CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_NAME =
  'CLEARING_INSPECTIONS_COUNTERAGENT_SELECT';
export const CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_PATH =
  'clearing.inspectionDocuments.counteragentSelect';
export const CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_CONFIG = {
  reducerName: CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_NAME,
  reducerPath: CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_PATH,
  optionsApiUrl: COUNTERAGENTS
};

export const CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE_NAME =
  'CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE';
export const CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE_PATH =
  'clearing.inspectionDocuments.vehicleAutocomplete';
export const CLEARING_INSPECTIONS_VEHICLE_SELECT_CONFIG = (id: number) => ({
  reducerName: CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE_NAME,
  reducerPath: CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE_PATH,
  optionsApiUrl: id
    ? `${COUNTERAGENTS}/${id}/vehicles-autocomplete?status=Enabled`
    : ''
});

export const CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE_NAME =
  'CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE';
export const CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE_PATH =
  'clearing.inspectionDocuments.inspectorAutocomplete';
export const CLEARING_INSPECTIONS_INSPECTOR_SELECT_CONFIG = {
  reducerName: CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE_NAME,
  reducerPath: CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE_PATH,
  optionsApiUrl: CURRENT_EMPLOYEES_AUTOCOMPLETE
};

export const KPI_INDICATOR_SELECT_NAME = 'KPI_INDICATOR_SELECT_NAME';
export const KPI_INDICATOR_SELECT_PATH =
  'clearing.inspectionDocuments.kpiIndicatorSelect';
export const KPI_INDICATOR_SELECT_CONFIG = (value: string | null) => ({
  reducerPath: KPI_INDICATOR_SELECT_PATH,
  reducerName: KPI_INDICATOR_SELECT_NAME,
  optionsApiUrl: value
    ? `${KPI_PARAMETERS_GROUPS}/kpi-parameters/${value}`
    : undefined
});

export const emptyKpiParamValues = {
  kpiId: null,
  gradeValue: null,
  maxValue: 0
};

export const defaultInitialValues = {
  documentDate: null,
  documentName: '',
  counteragentId: null,
  vehicle: null,
  employee: null,
  kpiParams: [emptyKpiParamValues]
};

const MAX_VALUE_ERROR = 'Значення повинно бути меншим ніж максимальне значення';

export const inspectionFormValidationScheme = object().shape({
  documentDate: date()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .nullable()
    .test('is-today', ERROR_MESSAGES.TYPE_ERROR, value =>
      moment(value).isSame(moment().format('YYYY-MM-DD'), 'day')
    ),
  documentName: string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR),
  counteragentId: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  vehicle: object()
    .nullable(false)
    .shape({
      id: number()
    })
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  employee: object()
    .nullable(false)
    .shape({
      id: number()
    })
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  kpiParams: array().of(
    object().shape({
      kpiId: number()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      gradeValue: number()
        .typeError(ERROR_MESSAGES.TYPE_ERROR)
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .min(1, ERROR_MESSAGES.AMOUNT_GREATER_ERROR)
        .test({
          name: 'gradeValue',
          message: ERROR_MESSAGES.TYPE_ERROR,
          test(value) {
            if (value && isNaN(value)) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.TYPE_ERROR
              });
            }
            if (Number(value) === 0) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.AMOUNT_GREATER_ERROR
              });
            }
            if (value > this.parent.maxValue) {
              throw this.createError({
                path: this.path,
                message: MAX_VALUE_ERROR
              });
            }
            if (!value) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.REQUIRED
              });
            }
            return this.resolve(value);
          }
        })
        .positive(ERROR_MESSAGES.TYPE_ERROR)
        .required(ERROR_MESSAGES.REQUIRED)
    })
  )
});
