import { Styles } from 'jspdf-autotable';
import * as XLSX from 'sheetjs-style';
import { SUMMARY_ROW_FILL_COLOR } from 'src/utils/xlsx/getSummaryRowStyles';
import { ReportTableConfig } from './types';

export const boldText = {
  font: {
    bold: true
  }
};
export const centered: Partial<XLSX.CellObject['s']> = {
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true
  }
};
export const leftAligned = {
  alignment: {
    horizontal: 'left',
    vertical: 'center',
    wrapText: true
  }
} as const;

export const summaryRowStyles = {
  font: {
    bold: true
  },
  fill: {
    fgColor: { rgb: '79aee7' }
  }
} as const;

export const transparentBorderStyle = {
  style: 'medium',
  color: { rgb: '00FFFFFF' }
};

// @ts-ignore
export const firstRowStyles: XLSX.CellObject['s'] = {
  ...boldText,
  ...centered
};

const borderThinBlack: object | any = { style: 'thin', color: { rgb: '0000' } };

export const borderStyle = {
  top: borderThinBlack,
  bottom: borderThinBlack,
  left: borderThinBlack,
  right: borderThinBlack
};

export const summaryRowFillColor = '79aee7';

export const cellStyles = {
  halign: 'center',
  valign: 'middle'
} as Partial<Styles>;

export const reportInfoCellStyles = {
  lineColor: 'white',
  fontStyle: 'bold'
} as Partial<Styles>;

export const headCellStyles = {
  ...cellStyles,
  fontStyle: 'bold'
} as Partial<Styles>;

export const summaryCellStyles = {
  ...cellStyles,
  fillColor: SUMMARY_ROW_FILL_COLOR,
  fontStyle: 'bold'
} as Partial<Styles>;

export const tableSemanticElements = {
  TITLE: 'title',
  METADATA: 'metadata',
  HEAD: 'head',
  BODY: 'body'
} as const;

export type TableConfig = Record<
  typeof tableSemanticElements[keyof typeof tableSemanticElements],
  ReportTableConfig
>;
