import { combineReducers } from 'redux';
import { createNamedWrapperReducer } from 'src/utils/reducers';
import tableWithPaginationReducers from 'src/components/Tables/TableWithPagination/store/reducers';
import {
  STOPS_BY_CODE_AUTOCOMPLETE_NAME,
  STOPS_TABLE_NAME
} from 'src/views/avl/stops/StopsListView/const';
import stopEditReducers from 'src/views/avl/stops/StopsEditView/store/reducers';
import stopDetailsReducers from 'src/views/avl/stops/StopsDetailsView/store/reducers';
import {
  DANGEROUS_ZONES_NAME,
  DANGEROUS_ZONES_TABLE_NAME
} from 'src/views/avl/dangerousZones/DangerousZonesListView/const';
import {
  EMPLOYEES_TABLE_NAME,
  PROFESSIONS_AUTOCOMPLETE_NAME,
  EMPLOYEES_AUTOCOMPLETE_ABBREVIATED_NAME
} from 'src/views/avl/employees/EmployeeListView/const';
import employeeDetailsReducers from 'src/views/avl/employees/EmployeeDetailsView/store/reducers';
import employeeEditReducers from 'src/views/avl/employees/EmployeeEditView/store/reducers';
import editableSelectReducers from 'src/components/EditableSelect/store/reducers';
import tableReducers from 'src/components/Tables/Table/store/reducers';
import {
  EMPLOYEES_COUNTERAGENTS_SELECT_NAME,
  PROFESSION_SELECT_NAME
} from 'src/views/avl/employees/const';
import { DANGEROUS_ZONES_CATEGORIES_NAME } from 'src/views/avl/dangerousZones/const';
import dangerousZoneDetailsReducer from 'src/views/avl/dangerousZones/DangerousZonesDetailsView/store/reducer';
import dangerousZonesEditReducers from 'src/views/avl/dangerousZones/DangerousZonesEditView/store/reducer';
import { COUNTERAGENTS_TABLE_NAME } from 'src/views/avl/counteragents/CounteragentsListView/const';
import counteragentDetailsReducers from 'src/views/avl/counteragents/CounteragentsDetailsView/store/reducers';
import {
  COUNTERAGENT_CHILDREN_NAME,
  COUNTERAGENT_TYPE_SELECT_NAME,
  COUNTERAGENTS_SELECT_NAME
} from 'src/views/avl/counteragents/const';
import { VEHICLES_MODELS_TABLE_NAME } from 'src/views/avl/vehiclesModels/VehiclesModelsListView/const';
import selectReducers from 'src/components/Select/store/reducers';
import vehicleModelsDetailsReducers from 'src/views/avl/vehiclesModels/VehiclesModelDetailsView/store/reducers';
import vehiclesModelEditReducers from 'src/views/avl/vehiclesModels/VehiclesModelEditView/store/reducers';
import vehiclesEditReducers from 'src/views/avl/vehicles/VehiclesEditView/store/reducers';
import {
  VEHICLES_CATEGORIES_NAME,
  TARIFF_GROUPS_SELECT_NAME,
  ADDITIONAL_EQUIPMENT_NAME
} from 'src/views/avl/vehiclesModels/const';
import vehicleDetailsReducer from 'src/views/avl/vehicles/VehicleDetailsView/store/reducers';
import {
  VEHICLE_MODEL_AUTOCOMPLETE_NAME,
  VEHICLES_TABLE_NAME
} from 'src/views/avl/vehicles/VehiclesListView/const';
import {
  VEHICLES_MODELS_SELECT_NAME,
  VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_NAME,
  VEHICLES_DRIVERS_AUTOCOMPLETE_NAME
} from 'src/views/avl/vehicles/const';
import counteragentsEditReducers from 'src/views/avl/counteragents/CounteragentsEditView/store/reducers';
import routesPassportLayoutReducers from 'src/views/avl/Routes/RoutesPassportLayout/store/reducer';
import routeSchemeCreateReducers from 'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeCreateView/store/reducer';
import { ACTIVE_TARIFFS_SELECT_NAME } from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Settings/const';
import settingsDetailsReducer from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Settings/SettingsDetailsView/store/reducers';
import {
  STOPS_AUTOCOMPLETE_NAME,
  ACTIVE_STOPS_AUTOCOMPLETE_NAME
} from 'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeForm/const';
import autocompleteReducers from 'src/components/Form/FormAutocomplete/store/reducer';
import { ROUTE_PASSPORTS_TABLE_NAME } from 'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeListView/const';
import {
  ROUTE_NUMBER_AUTOCOMPLETE_NAME,
  ROUTES_TABLE_NAME
} from 'src/views/avl/Routes/RoutesListView/const';
import { TIME_TABLE_NAME } from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/TimetableListView/const';
import {
  APPROVED_SCHEMES_SELECT_NAME,
  DEPOTS_SELECT_NAME,
  DEPARTURES_TABLE_NAME
} from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/const';
import routeSchemeDetailsReducer from 'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/store/reducer';
import tableViewReducer from 'src/components/Tables/TableView/store/reducers';
import timetableEditReducer from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/TimetableEditView/store/reducers';
import schemeStopsReducer from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DeparturesForm/IterationsForm/store/reducer';
import { TIME_TABLE_HISTORY_CHANGES_TABLE_NAME } from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/TimetableHistoryChangesListView/const';
import { trafficStadiumsReducer } from 'src/views/avl/dispatching/trafficStadium/store/reducer';
import {
  DISPATCHING_COUNTERAGENTS_NAME,
  DISPATCHING_ROUTES_NAME,
  DISPATCHING_AUTOCOMPLETE_ROUTES_NAME
} from 'src/views/avl/dispatching/const';
import departureDetailsReducer from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DepartureDetailsView/store/reducers';
import schedulesReducers from 'src/views/avl/dispatching/schedulePlanning/store/reducers';
import { DISRUPTION_REASONS_NAME } from 'src/views/avl/dispatching/trafficStadium/components/DisruptionReasonSelect/const';
import routeSchemeSummaryReducers from 'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeSummary/store/reducers';
import {
  DEPARTURE_TIMES_NAME,
  STADIUM_DEPOT_SELECT_NAME,
  STADIUM_DRIVERS_AUTOCOMPLETE_NAME,
  STADIUM_VEHICLES_AUTOCOMPLETE_NAME
} from 'src/views/avl/dispatching/trafficStadium/components/VehicleCard/const';
import { ROUTE_NUMBERS_SELECT_NAME } from 'src/views/clearing/penaltyDocuments/const';
import { ROUTE_SCHEMAS_HISTORY_LIST_TABLE_NAME } from 'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemesHistoryList/const';
import { ROUTE_HISTORY_LIST_TABLE_NAME } from 'src/views/avl/Routes/RoutesListView/RoutePassportHistoryList/const';
import { ROUTE_CHANGES_TABLE_NAME } from 'src/views/avl/Routes/RoutesListView/RoutePassportsHistoryList/const';
import avlReportsReducer from 'src/views/avl/reports/store/reducer';
import {
  SCHEDULES_DEPARTURES_NAME,
  SCHEDULES_STATUS_TABLE_NAME
} from 'src/views/avl/dispatching/schedulesStatus/const';
import trafficStadiumDialogsReducer from 'src/views/avl/dispatching/trafficStadium/components/VehicleCard/Dialogs/store/reducer';
import trafficMapReducer from 'src/views/avl/dispatching/trafficMap/store/reducer';

const avlReducers = combineReducers({
  dispatching: combineReducers({
    counteragents: createNamedWrapperReducer(
      autocompleteReducers,
      DISPATCHING_COUNTERAGENTS_NAME
    ),
    routes: createNamedWrapperReducer(selectReducers, DISPATCHING_ROUTES_NAME),
    routesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      DISPATCHING_AUTOCOMPLETE_ROUTES_NAME
    )
  }),
  schedulesStatus: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      SCHEDULES_STATUS_TABLE_NAME
    ),
    departuresSelect: createNamedWrapperReducer(
      selectReducers,
      SCHEDULES_DEPARTURES_NAME
    ),
    counteragents: createNamedWrapperReducer(
      selectReducers,
      DISPATCHING_COUNTERAGENTS_NAME
    )
  }),
  trafficMap: trafficMapReducer,
  trafficStadium: combineReducers({
    details: trafficStadiumsReducer,
    driversAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      STADIUM_DRIVERS_AUTOCOMPLETE_NAME
    ),
    depotSelect: createNamedWrapperReducer(
      selectReducers,
      STADIUM_DEPOT_SELECT_NAME
    ),
    vehiclesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      STADIUM_VEHICLES_AUTOCOMPLETE_NAME
    ),
    departureTimesSelect: createNamedWrapperReducer(
      selectReducers,
      DEPARTURE_TIMES_NAME
    ),
    dialogs: trafficStadiumDialogsReducer
  }),
  stops: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      STOPS_TABLE_NAME
    ),
    edit: stopEditReducers,
    details: stopDetailsReducers,
    autocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      STOPS_BY_CODE_AUTOCOMPLETE_NAME
    )
  }),
  dangerousZones: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      DANGEROUS_ZONES_TABLE_NAME
    ),
    create: combineReducers({
      editableSelect: createNamedWrapperReducer(
        editableSelectReducers,
        DANGEROUS_ZONES_CATEGORIES_NAME
      )
    }),
    details: dangerousZoneDetailsReducer,
    edit: dangerousZonesEditReducers,
    autocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      DANGEROUS_ZONES_NAME
    )
  }),
  employees: combineReducers({
    create: combineReducers({
      editableSelect: createNamedWrapperReducer(
        editableSelectReducers,
        PROFESSION_SELECT_NAME
      )
    }),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      EMPLOYEES_TABLE_NAME
    ),
    details: employeeDetailsReducers,
    edit: employeeEditReducers,
    select: createNamedWrapperReducer(
      autocompleteReducers,
      EMPLOYEES_COUNTERAGENTS_SELECT_NAME
    ),
    professionsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      PROFESSIONS_AUTOCOMPLETE_NAME
    ),
    employeeAutocompleteAbbreviated: createNamedWrapperReducer(
      autocompleteReducers,
      EMPLOYEES_AUTOCOMPLETE_ABBREVIATED_NAME
    )
  }),
  counteragents: combineReducers({
    select: createNamedWrapperReducer(
      selectReducers,
      COUNTERAGENTS_SELECT_NAME
    ),
    editableSelect: createNamedWrapperReducer(
      editableSelectReducers,
      COUNTERAGENT_TYPE_SELECT_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      COUNTERAGENTS_TABLE_NAME
    ),
    counteragentChildrenAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENT_CHILDREN_NAME
    ),
    details: counteragentDetailsReducers,
    edit: counteragentsEditReducers
  }),
  vehiclesModels: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      VEHICLES_MODELS_TABLE_NAME
    ),
    edit: vehiclesModelEditReducers,
    details: vehicleModelsDetailsReducers,
    vehiclesCategoriesEditableSelect: createNamedWrapperReducer(
      editableSelectReducers,
      VEHICLES_CATEGORIES_NAME
    ),
    tariffGroupsSelect: createNamedWrapperReducer(
      selectReducers,
      TARIFF_GROUPS_SELECT_NAME
    ),
    equipmentEditableSelect: createNamedWrapperReducer(
      editableSelectReducers,
      ADDITIONAL_EQUIPMENT_NAME
    )
  }),
  vehicles: combineReducers({
    details: vehicleDetailsReducer,
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      VEHICLES_TABLE_NAME
    ),
    vehicleModelsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      VEHICLE_MODEL_AUTOCOMPLETE_NAME
    ),
    create: combineReducers({
      counterAgentsAutocomplete: createNamedWrapperReducer(
        autocompleteReducers,
        VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_NAME
      ),
      vehicleModelsSelect: createNamedWrapperReducer(
        selectReducers,
        VEHICLES_MODELS_SELECT_NAME
      ),
      vehicleDriversAutocomplete: createNamedWrapperReducer(
        autocompleteReducers,
        VEHICLES_DRIVERS_AUTOCOMPLETE_NAME
      )
    }),
    edit: vehiclesEditReducers
  }),
  routes: combineReducers({
    settings: combineReducers({
      create: combineReducers({
        activeTariffsSelect: createNamedWrapperReducer(
          selectReducers,
          ACTIVE_TARIFFS_SELECT_NAME
        )
      }),
      details: settingsDetailsReducer,
      table: createNamedWrapperReducer(
        tableWithPaginationReducers,
        ROUTES_TABLE_NAME
      ),
      autocomplete: createNamedWrapperReducer(
        autocompleteReducers,
        ROUTE_NUMBERS_SELECT_NAME
      ),
      historyChangesTable: createNamedWrapperReducer(
        tableWithPaginationReducers,
        ROUTE_HISTORY_LIST_TABLE_NAME
      )
    }),
    schemes: combineReducers({
      create: routeSchemeCreateReducers,
      stopsAutocomplete: createNamedWrapperReducer(
        autocompleteReducers,
        STOPS_AUTOCOMPLETE_NAME
      ),
      activeStopsAutocomplete: createNamedWrapperReducer(
        autocompleteReducers,
        ACTIVE_STOPS_AUTOCOMPLETE_NAME
      ),
      table: createNamedWrapperReducer(
        tableReducers,
        ROUTE_PASSPORTS_TABLE_NAME
      ),
      historyChangesTable: createNamedWrapperReducer(
        tableWithPaginationReducers,
        ROUTE_SCHEMAS_HISTORY_LIST_TABLE_NAME
      ),
      details: routeSchemeDetailsReducer,
      summary: routeSchemeSummaryReducers
    }),
    timetable: combineReducers({
      approvedSchemesSelect: createNamedWrapperReducer(
        selectReducers,
        APPROVED_SCHEMES_SELECT_NAME
      ),
      depotsSelect: createNamedWrapperReducer(
        selectReducers,
        DEPOTS_SELECT_NAME
      ),
      table: createNamedWrapperReducer(
        tableWithPaginationReducers,
        TIME_TABLE_NAME
      ),
      historyChangesTable: createNamedWrapperReducer(
        tableWithPaginationReducers,
        TIME_TABLE_HISTORY_CHANGES_TABLE_NAME
      ),
      edit: timetableEditReducer
    }),
    departures: combineReducers({
      schemeStops: schemeStopsReducer,
      table: createNamedWrapperReducer(tableViewReducer, DEPARTURES_TABLE_NAME),
      details: departureDetailsReducer
    }),
    status: routesPassportLayoutReducers,
    disruptionReasons: createNamedWrapperReducer(
      editableSelectReducers,
      DISRUPTION_REASONS_NAME
    ),
    routeNumberAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ROUTE_NUMBER_AUTOCOMPLETE_NAME
    ),

    routesChangesTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      ROUTE_CHANGES_TABLE_NAME
    )
  }),
  schedules: schedulesReducers,
  reports: avlReportsReducer
});

export default avlReducers;
