import * as XLSX from 'sheetjs-style';
import {
  getSummaryRowStyles,
  SummaryRow
} from 'src/utils/xlsx/getSummaryRowStyles';
import { boldText, firstRowStyles, leftAligned } from '../const';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import {
  PRESENCE_ON_ROUTE_NETWORK_COLS_NUMBER,
  PRESENCE_ON_ROUTE_NETWORK_HEADERS_ROWS_NUMBER
} from './getTableConfig';
import { StyleBase } from '../RoutePerforming/types';
import { PresenceOnRouteNetwork } from './types';

const styleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText,
      ...leftAligned
    }
  },
  B2: {
    s: {
      ...boldText,
      ...leftAligned
    }
  },
  A4: {
    s: {
      ...boldText
    }
  },
  B4: {
    s: {
      ...boldText
    }
  },
  AX4: {
    s: {
      ...boldText
    }
  },
  B5: {
    s: {
      ...boldText
    }
  },
  B6: {
    s: {
      ...boldText
    }
  },
  C6: {
    s: {
      ...boldText
    }
  },
  D5: {
    s: {
      ...boldText
    }
  },
  D6: {
    s: {
      ...boldText
    }
  },
  E6: {
    s: {
      ...boldText
    }
  },
  F5: {
    s: {
      ...boldText
    }
  },
  F6: {
    s: {
      ...boldText
    }
  },
  G6: {
    s: {
      ...boldText
    }
  },
  H5: {
    s: {
      ...boldText
    }
  },
  H6: {
    s: {
      ...boldText
    }
  },
  I6: {
    s: {
      ...boldText
    }
  },
  J5: {
    s: {
      ...boldText
    }
  },
  J6: {
    s: {
      ...boldText
    }
  },
  K6: {
    s: {
      ...boldText
    }
  },
  L5: {
    s: {
      ...boldText
    }
  },
  L6: {
    s: {
      ...boldText
    }
  },
  M6: {
    s: {
      ...boldText
    }
  },
  N5: {
    s: {
      ...boldText
    }
  },
  N6: {
    s: {
      ...boldText
    }
  },
  O6: {
    s: {
      ...boldText
    }
  },
  P5: {
    s: {
      ...boldText
    }
  },
  P6: {
    s: {
      ...boldText
    }
  },
  Q6: {
    s: {
      ...boldText
    }
  },
  R5: {
    s: {
      ...boldText
    }
  },
  R6: {
    s: {
      ...boldText
    }
  },
  S6: {
    s: {
      ...boldText
    }
  },
  T5: {
    s: {
      ...boldText
    }
  },
  T6: {
    s: {
      ...boldText
    }
  },
  U6: {
    s: {
      ...boldText
    }
  },
  V5: {
    s: {
      ...boldText
    }
  },
  V6: {
    s: {
      ...boldText
    }
  },
  W6: {
    s: {
      ...boldText
    }
  },
  X5: {
    s: {
      ...boldText
    }
  },
  X6: {
    s: {
      ...boldText
    }
  },
  Y6: {
    s: {
      ...boldText
    }
  },
  Z5: {
    s: {
      ...boldText
    }
  },
  Z6: {
    s: {
      ...boldText
    }
  },
  AA6: {
    s: {
      ...boldText
    }
  },
  AB5: {
    s: {
      ...boldText
    }
  },
  AB6: {
    s: {
      ...boldText
    }
  },
  AC6: {
    s: {
      ...boldText
    }
  },
  AD5: {
    s: {
      ...boldText
    }
  },
  AD6: {
    s: {
      ...boldText
    }
  },
  AE6: {
    s: {
      ...boldText
    }
  },
  AF5: {
    s: {
      ...boldText
    }
  },
  AF6: {
    s: {
      ...boldText
    }
  },
  AG6: {
    s: {
      ...boldText
    }
  },
  AJ5: {
    s: {
      ...boldText
    }
  },
  AJ6: {
    s: {
      ...boldText
    }
  },
  AK6: {
    s: {
      ...boldText
    }
  },
  AL5: {
    s: {
      ...boldText
    }
  },
  AL6: {
    s: {
      ...boldText
    }
  },
  AM6: {
    s: {
      ...boldText
    }
  },
  AH5: {
    s: {
      ...boldText
    }
  },
  AH6: {
    s: {
      ...boldText
    }
  },
  AI6: {
    s: {
      ...boldText
    }
  },
  AN5: {
    s: {
      ...boldText
    }
  },
  AN6: {
    s: {
      ...boldText
    }
  },
  AO6: {
    s: {
      ...boldText
    }
  },
  AP5: {
    s: {
      ...boldText
    }
  },
  AP6: {
    s: {
      ...boldText
    }
  },
  AQ6: {
    s: {
      ...boldText
    }
  },
  AR5: {
    s: {
      ...boldText
    }
  },
  AR6: {
    s: {
      ...boldText
    }
  },
  AS6: {
    s: {
      ...boldText
    }
  },
  AT5: {
    s: {
      ...boldText
    }
  },
  AT6: {
    s: {
      ...boldText
    }
  },
  AU6: {
    s: {
      ...boldText
    }
  },
  AV5: {
    s: {
      ...boldText
    }
  },
  AV6: {
    s: {
      ...boldText
    }
  },
  AW6: {
    s: {
      ...boldText
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 3, c: 0 };

export const getStyles = (report: PresenceOnRouteNetwork) => {
  let tableRowTopOffset = PRESENCE_ON_ROUTE_NETWORK_HEADERS_ROWS_NUMBER;

  const summaryRows = report.counteragents.reduce((accum, counteragent) => {
    const counteragentTitleRow = tableRowTopOffset + 1;
    const counteragentSummaryRow =
      counteragentTitleRow + counteragent.routes.length + 1;

    tableRowTopOffset = counteragentSummaryRow;

    return accum.concat([
      { row: counteragentTitleRow },
      {
        row: counteragentSummaryRow,
        styles: {
          A: { s: leftAligned }
        }
      }
    ]);
  }, [] as SummaryRow[]);

  return getStylesForReportWithTable(
    {
      ...styleBase,
      ...getSummaryRowStyles(summaryRows, PRESENCE_ON_ROUTE_NETWORK_COLS_NUMBER)
    } as StyleBase,
    TABLE_START_ADDRESS
  );
};
