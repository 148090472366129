import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  '/asset/dictionaries/breakdownsTypes/:id/details'
);

export const selectBreakdownsTypesId = createSelector(matchSpaceParams, match =>
  match ? match.params.id : ''
);

export const selectBreakdownsTypesName = (state: RootState): string =>
  state.ui.asset.breakdownsTypes.details.name;

export const selectBreakdownsTypesRows = (state: RootState) => {
  const breakdownsTypesDetails = get(state, `ui.asset.breakdownsTypes.details`);

  return [
    { label: 'Назва несправності', value: breakdownsTypesDetails.name },
    {
      label: 'Стан обладнання',
      value: breakdownsTypesDetails.state
    }
  ];
};

export const selectIsBreakdownsTypesLoading = (state: RootState) => {
  const loading = get(state, `loading.@BREAKDOWNS_TYPES_DETAILS/GET_BREAKDOWN`);
  return isBoolean(loading) ? loading : true;
};

export const selectBreakdownTypeIsUsed = (state: RootState) =>
  state.ui.asset.breakdownsTypes.details.isUsed;
