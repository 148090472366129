import { GraphicityPerformance } from '../types';

export const mockedGraphicityPerformanceReport: GraphicityPerformance = [
  {
    departureName: 'Назва 1',
    iterationNumber: 1,
    vehicles: [
      {
        vehicleId: 1,
        vehicleLicensePlate: 'AB1234BC',
        busStops: [
          {
            busStopId: 1,
            busStopName: 'Зупинка 1',
            busStopCode: '01234',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 100,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          },
          {
            busStopId: 2,
            busStopName: 'Зупинка 2',
            busStopCode: '55555',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 122,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          }
        ]
      },
      {
        vehicleId: 2,
        vehicleLicensePlate: 'BC9999DC',
        busStops: [
          {
            busStopId: 3,
            busStopName: 'Зупинка 3',
            busStopCode: '01234',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 100,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          },
          {
            busStopId: 4,
            busStopName: 'Зупинка 4',
            busStopCode: '55555',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 122,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          }
        ]
      }
    ]
  },
  {
    departureName: 'Назва 1',
    iterationNumber: 2,
    vehicles: [
      {
        vehicleId: 3,
        vehicleLicensePlate: 'AB1234BC',
        busStops: [
          {
            busStopId: 5,
            busStopName: 'Зупинка 5',
            busStopCode: '01234',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 100,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          },
          {
            busStopId: 6,
            busStopName: 'Зупинка 6',
            busStopCode: '55555',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 122,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          }
        ]
      },
      {
        vehicleId: 4,
        vehicleLicensePlate: 'BC9999DC',
        busStops: [
          {
            busStopId: 7,
            busStopName: 'Зупинка 7',
            busStopCode: '01234',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 100,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          },
          {
            busStopId: 8,
            busStopName: 'Зупинка 8',
            busStopCode: '55555',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 122,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          }
        ]
      },
      {
        vehicleId: 5,
        vehicleLicensePlate: 'HG0000DG',
        busStops: [
          {
            busStopId: 9,
            busStopName: 'Зупинка 9',
            busStopCode: '01234',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 100,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          },
          {
            busStopId: 10,
            busStopName: 'Зупинка 10',
            busStopCode: '55555',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 122,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          },
          {
            busStopId: 11,
            busStopName: 'Зупинка 11',
            busStopCode: '0102',
            plannedDateArrive: '29.05.2021 7:28',
            plannedDateDeparture: '29.05.2021 7:30',
            factDateArrive: '29.05.2021 7:35',
            factDateDeparture: '29.05.2021 7:36',
            deviationInSec: 122,
            notes: 'Запізнення',
            departureName: '',
            iterationNumber: '',
            vehicleLicensePlate: ''
          }
        ]
      }
    ]
  }
];
