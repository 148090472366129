import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import { Row } from 'src/components/Tables/types';
import AlertIcon from 'src/components/AlertIcon';

export const ROUTE_PASSPORTS_TABLE_PATH = 'avl.routes.schemes.table';
export const ROUTE_PASSPORTS_TABLE_NAME = 'ROUTE_PASSPORTS';

export const ROUTE_PASSPORTS_SUCCESS_MESSAGE = 'Схему видалено';

export const columns = [
  {
    label: 'Назва схеми',
    value: 'name',
    sortable: true,
    width: '35%'
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '30%',
    changeValue: changeStatusColumn
  },
  {
    label: 'Кількість графіків',
    value: 'count',
    sortable: false,
    width: '25%'
  },
  {
    label: '',
    value: '',
    sortable: false,
    width: '5%',
    changeValue: (row: Row) => (
      <>
        {row.isObsolete === true && (
          <AlertIcon title="Оновлено зупинку, внесіть зміни та затвердіть схему та відповідні графіки" />
        )}
      </>
    )
  }
];
