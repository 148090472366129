import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import { Row } from 'src/components/Tables/types';
import { PassportStatusEnum } from 'src/views/avl/Routes/const';
import AlertIcon from 'src/components/AlertIcon';

export const columns = [
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '20%'
  },
  {
    label: 'К-ть випусків',
    value: 'count',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Період дії',
    value: 'actualFrom',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Схема',
    value: 'schemeName',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '5%',
    changeValue: (row: Row) => changeStatusColumn(row)
  },
  {
    label: '',
    value: '',
    sortable: false,
    width: '5%',
    changeValue: (row: Row) => {
      let title = '';

      if (row.status === PassportStatusEnum.Draft) {
        title =
          'Відбулися зміни в схемі руху, перевірте та затвердьте дані графіку';
      }

      if (row.isObsolete === true) {
        title =
          'Оновлено схему руху, cтворіть нові графіки, які відповідатимуть схемі';
      }

      if (title.length > 0) {
        return <AlertIcon title={title} />;
      }

      return <></>;
    }
  }
];

export const TIME_TABLE_PATH = 'avl.routes.timetable.table';
export const TIME_TABLE_NAME = 'TIME_TABLE';

export const filters = [
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Затверджено',
        value: PassportStatusEnum.Approved
      },
      {
        label: 'Чернетка',
        value: PassportStatusEnum.Draft
      }
    ]
  }
];
