import { BillingDetailsLevels } from '../types';

export const getLevelLink = (
  level: BillingDetailsLevels,
  id?: number,
  routeId?: number
): string =>
  `/clearing/billing-details/${level}/${id || ''}${
    routeId ? `/${routeId}` : ''
  }`;

export const formatEmptyValue = (value?: number | string) =>
  String(value) ?? '-';

export const formatTableValue = (
  value?: number | string,
  fixed: number = 3
) => {
  const parsedValue = parseFloat(value as string);

  if (+parsedValue === 0) {
    return '0';
  }

  return formatEmptyValue(parsedValue?.toFixed(fixed));
};

export const formatPercentageValue = (percentageValue: number) => {
  const value = `${percentageValue}`;

  if ([0, 100].includes(percentageValue)) {
    return value;
  }

  return parseFloat(value).toFixed(3);
};
