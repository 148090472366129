import { object, string, number, date } from 'yup';
import { amountRegExp, ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import moment from 'moment';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { ROUTE_PASSPORT_AUTOCOMPLETE, COUNTERAGENTS } from 'src/config/avlUrls';
import { PENALTIES_AUTOCOMPLETE } from 'src/config/clearingUrls';

export const crudActions = generateActionCrud({
  module: 'clearing',
  sub: 'penaltyDocument'
});

export const crudActionsList = generateActionCrud({
  module: 'clearing',
  sub: 'penaltyDocument'
});

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.penaltyDocuments,
    url: '/clearing/penalty-documents'
  }
];

export const ROUTE_NUMBERS_SELECT_NAME = 'PENALTY_ROUTE_NUMBERS';
export const ROUTE_NUMBERS_SELECT_PATH = 'avl.routes.settings.autocomplete';
export const ROUTES_NUMBERS_SELECT_CONFIG = (id?: number) => ({
  reducerPath: ROUTE_NUMBERS_SELECT_PATH,
  reducerName: ROUTE_NUMBERS_SELECT_NAME,
  optionsApiUrl: id ? `${ROUTE_PASSPORT_AUTOCOMPLETE}?counteragentId=${id}` : ''
});

export const PENALTY_DOCUMENTS_SELECT_NAME = 'PENALTIES_AUTOCOMPLETE';
export const PENALTY_DOCUMENTS_SELECT_PATH = 'clearing.penalties.autocomplete';
export const PENALTY_DOCUMENTS_SELECT_CONFIG = {
  reducerPath: PENALTY_DOCUMENTS_SELECT_PATH,
  reducerName: PENALTY_DOCUMENTS_SELECT_NAME,
  optionsApiUrl: PENALTIES_AUTOCOMPLETE
};

export const COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_NAME =
  'COUNTERAGENTS_VEHICLES';
export const COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_PATH =
  'clearing.penaltyDocuments.vehiclesAutocomplete';
export const COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_CONFIG = (id?: number) => ({
  optionsApiUrl: id ? `${COUNTERAGENTS}/${id}/vehicles-autocomplete` : '',
  reducerPath: COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_PATH,
  reducerName: COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_NAME
});

export const penaltyDocumentSchema = object().shape({
  documentDate: date()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .test('is-today', ERROR_MESSAGES.TYPE_ERROR, value =>
      moment(value).isSame(moment().format('YYYY-MM-DD'), 'day')
    ),
  documentName: string().max(...maxLength(100)),
  routePassport: object()
    .shape({
      id: number(),
      name: string()
    })
    .default(null)
    .nullable(),
  counteragent: object()
    .shape({
      id: number(),
      name: string()
    })
    .default(null)
    .nullable()
    .required(ERROR_MESSAGES.REQUIRED),
  vehicle: object()
    .shape({
      id: number(),
      name: string()
    })
    .default(null)
    .nullable(),
  penalty: object()
    .shape({
      id: number(),
      name: string()
    })
    .default(null)
    .nullable()
    .required(ERROR_MESSAGES.REQUIRED),
  amount: string()
    .typeError(ERROR_MESSAGES.AMOUNT_TYPE_ERROR)
    .required(ERROR_MESSAGES.REQUIRED)
    .test({
      name: 'amount',
      message: ERROR_MESSAGES.AMOUNT_TYPE_ERROR,
      test(value) {
        if (value && isNaN(value)) {
          throw this.createError({
            path: this.path,
            message: ERROR_MESSAGES.TYPE_ERROR
          });
        }
        if (Number(value) === 0) {
          throw this.createError({
            path: this.path,
            message: ERROR_MESSAGES.AMOUNT_GREATER_ERROR
          });
        }
        return amountRegExp.test(String(value).trim());
      }
    })
});

export enum PenaltyDocumentStatus {
  Imposed = 0,
  Calculated = 1
}

export const penaltyDocumentStatusMap = {
  [PenaltyDocumentStatus.Imposed]: 'Imposed',
  [PenaltyDocumentStatus.Calculated]: 'Calculated'
};
