import { WorkOfDriverReport } from '../types';

export const workOfDriverReportMock: WorkOfDriverReport = {
  counteragents: [
    {
      counteragentId: 1,
      counteragentName: 'Name',
      counteragentFullName: 'FullName',
      summary: {
        attendance: 1,
        graphicity: 1,
        completeness: 1,
        distanceOnRoute: {
          fact: 1,
          planned: 1
        },
        numberOfRides: {
          fact: 1,
          planned: 1
        },
        visitedBusStops: {
          fact: 1,
          planned: 1,
          onSchedule: 1,
          inSpecialMode: 1,
          unavailableStops: 1
        }
      },
      rides: [
        {
          date: '21/08/2021T00:00:00',
          driverName: 'Petro M.N.',
          vehicle: '1',
          route: 'Zaliznichniy - Stryiska',
          departureTime: '11:45',
          startTimePlan: '12:00',
          startTimeActual: '12:01',
          endTimePlan: '18:20',
          endTimeActual: '18:15',
          arrivalTime: '18:25',
          summary: {
            attendance: 1,
            graphicity: 1,
            completeness: 1,
            distanceOnRoute: {
              fact: 1,
              planned: 1
            },
            numberOfRides: {
              fact: 1,
              planned: 1
            },
            visitedBusStops: {
              fact: 1,
              planned: 1,
              onSchedule: 1,
              inSpecialMode: 1,
              unavailableStops: 1
            }
          }
        }
      ]
    }
  ]
};
