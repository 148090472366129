import { TableRow } from '../../types';
import { MorningEveningRideData, MorningEveningRidesReport } from '../types';

export const testMocks: MorningEveningRideData[] = [
  {
    licensePlate: 'licensePlate',
    busStopName: 'busStopName',
    busStopCode: 'busStopCode',
    departure: {
      planned: '',
      fact: ''
    },
    arrival: {
      planned: '',
      fact: ''
    },
    departureName: ''
  }
];

export const testMockRows: TableRow[] = [
  [
    { value: 'licensePlate' },
    { value: 'busStopName (busStopCode)' },
    { value: '-' },
    { value: '-' },
    { value: '-' },
    { value: '-' }
  ]
];

export const morningEveningRidesReportMock: MorningEveningRidesReport = [
  {
    routeName: '33 с.Білогорща – пл. Різні',
    licensePlate: 'АА2233ВВ',
    busStopName: 'Вікторія Гарденс',
    busStopCode: '0001',
    departure: {
      planned: '6:13',
      fact: '6:14'
    },
    arrival: {
      planned: null,
      fact: null
    },
    departureName: ''
  },
  {
    routeName: '33 с.Білогорща – пл. Різні',
    licensePlate: 'АА2233ВВ',
    busStopName: 'Вікторія Гарденс',
    busStopCode: '0001',
    departure: {
      planned: null,
      fact: null
    },
    arrival: {
      planned: '23:00',
      fact: '23:01'
    },
    departureName: ''
  }
];
