import { SheetDimensions } from '../../../../../../../utils/downloadXlsxFileFromTable';

const dimensions: SheetDimensions = {
  column: {
    widths: {
      A5: 70,
      B5: 70,
      D5: 70,
      H5: 70
    }
  }
};

export default dimensions;
