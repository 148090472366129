import * as XLSX from 'sheetjs-style';
import {
  getSummaryRowStyles,
  SummaryRow
} from 'src/utils/xlsx/getSummaryRowStyles';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { boldText, firstRowStyles, leftAligned } from '../const';
import { StyleBase } from '../RoutePerforming/types';
import {
  WORK_OF_DRIVER_COLS_NUMBER,
  WORK_OF_DRIVER_HEADERS_ROWS_NUMBER
} from './getTableConfig';
import { WorkOfDriverReport } from './types';

const styleAlignLeft = {
  ...leftAligned,
  font: {
    bold: true
  }
} as Record<string, object>;

const styleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: styleAlignLeft
  },
  A3: {
    s: styleAlignLeft
  },
  B2: {
    s: styleAlignLeft
  },
  B3: {
    s: styleAlignLeft
  },
  A5: {
    s: {
      ...boldText
    }
  },
  B5: {
    s: {
      ...boldText
    }
  },
  C5: {
    s: {
      ...boldText
    }
  },
  D5: {
    s: {
      ...boldText
    }
  },
  E5: {
    s: {
      ...boldText
    }
  },
  F5: {
    s: {
      ...boldText
    }
  },
  H5: {
    s: {
      ...boldText
    }
  },
  J5: {
    s: {
      ...boldText
    }
  },
  K5: {
    s: {
      ...boldText
    }
  },
  N5: {
    s: {
      ...boldText
    }
  },
  R5: {
    s: {
      ...boldText
    }
  },
  F6: {
    s: {
      ...boldText
    }
  },
  G6: {
    s: {
      ...boldText
    }
  },
  H6: {
    s: {
      ...boldText
    }
  },
  I6: {
    s: {
      ...boldText
    }
  },
  K6: {
    s: {
      ...boldText
    }
  },
  L6: {
    s: {
      ...boldText
    }
  },
  M6: {
    s: {
      ...boldText
    }
  },
  N6: {
    s: {
      ...boldText
    }
  },
  O6: {
    s: {
      ...boldText
    }
  },
  P6: {
    s: {
      ...boldText
    }
  },
  Q6: {
    s: {
      ...boldText
    }
  },
  R6: {
    s: {
      ...boldText
    }
  },
  S6: {
    s: {
      ...boldText
    }
  },
  T6: {
    s: {
      ...boldText
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 4, c: 0 };

export const getStyles = (report: WorkOfDriverReport) => {
  let tableRowTopOffset = WORK_OF_DRIVER_HEADERS_ROWS_NUMBER;

  const summaryRows = report.counteragents.reduce((accum, counteragent) => {
    const counteragentTitleRow = tableRowTopOffset + 1;
    const counteragentSummaryRow =
      counteragentTitleRow + counteragent.rides.length + 1;

    tableRowTopOffset = counteragentSummaryRow;

    return accum.concat([
      { row: counteragentTitleRow },
      {
        row: counteragentSummaryRow,
        styles: {
          A: { s: styleAlignLeft }
        }
      }
    ]);
  }, [] as SummaryRow[]);

  return getStylesForReportWithTable(
    {
      ...styleBase,
      ...getSummaryRowStyles(summaryRows, WORK_OF_DRIVER_COLS_NUMBER)
    } as StyleBase,
    TABLE_START_ADDRESS
  );
};
