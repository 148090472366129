import * as L from 'leaflet';
import { LatLng, LatLngLiteral } from 'leaflet';
import * as turf from '@turf/turf';
import { last } from 'lodash';
import { LAT_LON_REGEX } from '../constants/map';
import { RouteStop } from '../components/Maps/MapRoute/types';

export const parseCoordinates = (coordinates: string): string[] =>
  coordinates.split(',').map((item: string) => item.trim());

export const stringifyCoordinates = (coordinates: LatLngLiteral) =>
  `${coordinates.lat}, ${coordinates.lng}`;

export const getOptionalCoordinates = (
  coordinates: string,
  defaultCoordinates: LatLngLiteral
) => {
  if (LAT_LON_REGEX.test(coordinates)) {
    const [lat, lng] = parseCoordinates(coordinates);

    return { lat: +lat, lng: +lng };
  }

  return defaultCoordinates;
};

export function getBorders<T>(arr: T[]) {
  return [arr[0], arr[arr.length - 1]];
}

const getDistance = (coord1: LatLngLiteral, coord2: LatLngLiteral) => {
  const point1 = turf.point([coord1.lng, coord1.lat]);
  const point2 = turf.point([coord2.lng, coord2.lat]);
  return turf.distance(point1, point2, { units: 'meters' });
};

export function areCoordinatesClose(
  coord1: LatLngLiteral,
  coord2: LatLngLiteral,
  threshold: number = 10
): boolean {
  const point1 = turf.point([coord1.lng, coord1.lat]);
  const point2 = turf.point([coord2.lng, coord2.lat]);
  const distance = turf.distance(point1, point2, { units: 'meters' });

  return distance <= threshold;
}

type Connection = {
  thresholdStart: number;
  thresholdEnd: number;
  segment: L.LatLng[];
};

export function validateConnections(
  stops: RouteStop[],
  segments: L.LatLng[][],
  radius: number
) {
  const missedConnections: [number, number][] = [];
  const unconnectedStops: { [id: number]: true } = {};
  const newSegments: L.LatLng[][] = [];

  for (let i = 0; i < stops.length; i++) {
    const currentStop = stops[i];
    const nextStopIdx = (i + 1) % stops.length; // Get next stop, loop back to the first stop if at the end
    const nextStop = stops[nextStopIdx];

    let newConnection: Connection | null = null;

    for (const segment of segments) {
      const segmentStart = segment[0];
      const segmentEnd = last(segment)!;
      const equal =
        areCoordinatesClose(currentStop.coordinates!, segmentStart, radius) &&
        areCoordinatesClose(nextStop.coordinates!, segmentEnd, radius);
      const reverseEqual =
        areCoordinatesClose(currentStop.coordinates!, segmentEnd, radius) &&
        areCoordinatesClose(nextStop.coordinates!, segmentStart, radius);

      const newStart = new LatLng(
        currentStop.coordinates!.lat,
        currentStop.coordinates!.lng
      );
      const newEnd = new LatLng(
        nextStop.coordinates!.lat,
        nextStop.coordinates!.lng
      );
      const rest = segment.slice(1, segment.length - 1);

      let connection: Connection = {
        thresholdStart: 0,
        thresholdEnd: 0,
        segment: []
      };

      if (equal) {
        connection = {
          thresholdStart: getDistance(currentStop.coordinates!, segmentStart),
          thresholdEnd: getDistance(nextStop.coordinates!, segmentEnd),
          segment: [newStart, ...rest, newEnd]
        };
      } else if (reverseEqual) {
        connection = {
          thresholdStart: getDistance(currentStop.coordinates!, segmentEnd),
          thresholdEnd: getDistance(nextStop.coordinates!, segmentStart),
          segment: [newEnd, ...rest, newStart]
        };
      } else {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (
        !newConnection ||
        connection.thresholdStart + connection.thresholdEnd <
          newConnection.thresholdStart + newConnection.thresholdEnd
      ) {
        newConnection = connection;
      }
    }

    if (newConnection?.segment.length) {
      newSegments.push(newConnection.segment);
    } else {
      unconnectedStops[currentStop.id!] = true;
      unconnectedStops[nextStop.id!] = true;
      missedConnections.push([i + 1, nextStopIdx + 1]);
    }
  }

  return { missedConnections, unconnectedStops, newSegments };
}

export function buildUnconnectedStopsErrorMessage(
  missingConnections: [number, number][]
): string {
  if (missingConnections.length === 0) {
    return '';
  }

  const errorParts: string[] = [];

  for (const [first, second] of missingConnections) {
    errorParts.push(`${first} та ${second}`);
  }

  return `Зупинки ${errorParts.join(', ')} не зʼєднані`;
}

type PointCoordinate = LatLngLiteral & {
  key: string;
};

export const findClosestCoordinate = (
  coordinates: PointCoordinate[],
  targetCoordinate: LatLngLiteral
) => {
  const targetPoint = turf.point([targetCoordinate.lat, targetCoordinate.lng]);
  let minDistance = Infinity;
  let closestPoint: PointCoordinate;

  for (const coordinate of coordinates) {
    const point = turf.point([coordinate.lat, coordinate.lng]);
    const distance = turf.distance(targetPoint, point);

    if (distance < minDistance) {
      minDistance = distance;
      closestPoint = coordinate;
    }
  }

  return closestPoint!;
};
