import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import moment from 'moment';
import { Row } from 'src/components/Tables/types';
import { BillingDetailsLevels } from '../../types';
import { BILLING_DOCUMENTS_LOADING_KEY } from '../../const';
import { formatTableValue } from '../utils';

interface Params {
  level: BillingDetailsLevels;
  id?: string;
  routeId?: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/clearing/billing-details/:level(carrier|routes|vehicles)/:id?/:routeId?'
);

export const selectBillingTableParams = createSelector(
  matchSpaceParams,
  match =>
    match?.params || {
      level: BillingDetailsLevels.Carrier
    }
);

export const selectBillingDetailsRows = (state: RootState) =>
  state.ui.clearing.billingDocuments.detailsTable.list.results.map(
    ({
      routePassportName,
      routePassportNumber,
      routeRideScheduleDate,
      ...row
    }: Row) => ({
      ...row,
      plannedDistanceByTrack: formatTableValue(row.plannedDistanceByTrack),
      distanceByRoute: formatTableValue(row.distanceByRoute),
      distanceFromDepot: formatTableValue(row.distanceFromDepot),
      distanceToDepot: formatTableValue(row.distanceToDepot),
      distanceInSpecialMode: formatTableValue(row.distanceInSpecialMode),
      totalDistance: formatTableValue(row.totalDistance),
      routePassportName: `${routePassportNumber}, ${routePassportName}`,
      routeRideScheduleDate: moment(routeRideScheduleDate).format('DD/MM/YYYY')
    })
  );

export const selectIsLoading = (state: RootState): boolean =>
  get(state, `loading.${BILLING_DOCUMENTS_LOADING_KEY}`);
