import * as XLSX from 'sheetjs-style';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { boldText, firstRowStyles } from '../const';
import { StyleBase } from './types';

const cells = [
  'A6',
  'B6',
  'C6',
  'D6',
  'E6',
  'F6',
  'G6',
  'A7',
  'B7',
  'C7',
  'D7',
  'E7',
  'F7',
  'G7'
];

const getTableHeadersStyles = () => {
  return cells.reduce<StyleBase>(
    (acc, cell) => ({
      ...acc,
      [cell]: {
        s: {
          ...boldText
        }
      }
    }),
    {}
  );
};

const styleBase: StyleBase = {
  A1: {
    s: {
      ...firstRowStyles
    }
  },
  A3: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  A4: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  B3: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  B4: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  C2: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  C3: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  ...getTableHeadersStyles()
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 4, c: 0 };

export const getStyles = () =>
  getStylesForReportWithTable(styleBase, TABLE_START_ADDRESS);
