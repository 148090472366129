import { flatten } from 'lodash';
import { emptyDataPlaceholder } from 'src/utils/text';
import { formatPercentageValue } from 'src/views/clearing/billingDocuments/BillingDetailsListView/utils';
import { PresenceOnRouteNetwork } from './types';
import { TableRow } from '../types';
import { ReportFilterData } from '../../types';
import {
  cellStyles,
  headCellStyles,
  reportInfoCellStyles,
  summaryCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';

export const title = '8. Звіт про перебування на маршрутній мережі';

export const getTableConfig = (
  filterData: ReportFilterData,
  report: PresenceOnRouteNetwork
): TableConfig => {
  const times = report.counteragents[0]?.routes[0]?.timeSpans || [];

  const formattedWorkingHours = times.map(
    ({ from, to }) => `з ${from} до ${to}`
  );

  const tableColsNumber = formattedWorkingHours.length * 2 + 2;

  return {
    [tableSemanticElements.TITLE]: [
      [
        {
          colSpan: tableColsNumber,
          value: title,
          styles: { ...reportInfoCellStyles, halign: 'center' }
        }
      ]
    ],
    [tableSemanticElements.METADATA]: [
      [
        {
          value: 'Дата',
          styles: reportInfoCellStyles
        },
        {
          colSpan: tableColsNumber - 1,
          value: filterData.date,
          styles: reportInfoCellStyles
        }
      ],
      []
    ],
    [tableSemanticElements.HEAD]: [
      [
        {
          rowSpan: 3,
          value: 'Маршрут',
          styles: { ...headCellStyles, cellWidth: 16 }
        },
        {
          colSpan: tableColsNumber - 2,
          value: 'Години доби',
          styles: headCellStyles
        },
        {
          rowSpan: 3,
          value: 'Присутність на маршруті, %',
          styles: { ...headCellStyles, cellWidth: 12 }
        }
      ],
      formattedWorkingHours.map(value => ({
        value,
        colSpan: 2,
        styles: headCellStyles
      })),
      [
        ...flatten(
          formattedWorkingHours.map(() => [
            { value: 'план', styles: headCellStyles },
            { value: 'факт', styles: headCellStyles }
          ])
        )
      ]
    ],
    [tableSemanticElements.BODY]: report.counteragents
      .map(counteragent => {
        const counteragentTitleRow = [
          {
            value: counteragent.name,
            colSpan: tableColsNumber,
            styles: summaryCellStyles
          }
        ];

        const counteragentRoutes = counteragent.routes.map(route => [
          { value: emptyDataPlaceholder(route.name), styles: cellStyles },
          ...route.timeSpans
            .map(({ planned, fact }) => [
              {
                value: planned || '-',
                styles: cellStyles
              },
              { value: fact || '-', styles: cellStyles }
            ])
            .flat(),
          {
            value: emptyDataPlaceholder(
              formatPercentageValue(route.ratioPerDay)
            ),
            styles: cellStyles
          }
        ]);

        const counteragentSummaryRow = [
          {
            value: `В середньому за день роботи (по перевізнику ${counteragent.name})`,
            colSpan: tableColsNumber - 1,
            styles: { ...summaryCellStyles, halign: 'left' }
          },
          {
            value: emptyDataPlaceholder(
              formatPercentageValue(counteragent.ratioPerDay)
            ),
            styles: summaryCellStyles
          }
        ] as TableRow;

        return [
          counteragentTitleRow,
          ...counteragentRoutes,
          counteragentSummaryRow
        ];
      })
      .flat()
  };
};

export const PRESENCE_ON_ROUTE_NETWORK_HEADERS_ROWS_NUMBER = 6;
export const PRESENCE_ON_ROUTE_NETWORK_COLS_NUMBER = 50;
