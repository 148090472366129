import { Styles } from 'jspdf-autotable';
import moment from 'moment';
import { DETAILED_DATE_FORMAT } from 'src/config';
import { emptyDataPlaceholder } from 'src/utils/text';
import { ReportFilterData } from '../../types';
import { UnmappedGPSDataReport } from './types';
import {
  cellStyles,
  headCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';

export const title = '9. Звіт про отримані неідентифіковані GPS дані';

export const getReportDate = (date: string) =>
  moment(date, DETAILED_DATE_FORMAT).format('DD/MM/YYYY HH:mm:ss');

const reportDetailsStyles: Partial<Styles> = {
  lineColor: 'white',
  halign: 'left',
  fontStyle: 'bold'
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: UnmappedGPSDataReport
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        colSpan: 8,
        value: title,
        styles: {
          lineColor: 'white',
          fontStyle: 'bold',
          halign: 'center'
        }
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [
      {
        value: 'Дата з',
        colSpan: 1,
        styles: reportDetailsStyles
      },
      {
        colSpan: 7,
        value: filterData.dateFrom,
        styles: reportDetailsStyles
      }
    ],
    [
      {
        value: 'Дата до',
        colSpan: 1,
        styles: reportDetailsStyles
      },
      {
        colSpan: 7,
        value: filterData.dateTo,
        styles: reportDetailsStyles
      }
    ],
    []
  ],
  [tableSemanticElements.HEAD]: [
    [
      {
        value: 'Дата та час',
        colSpan: 1,
        rowSpan: 2,
        styles: headCellStyles
      },
      {
        value: 'Штрихкод консолі',
        colSpan: 1,
        rowSpan: 2,
        styles: headCellStyles
      },
      {
        value: 'AVL',
        colSpan: 3,
        styles: headCellStyles
      },
      {
        value: 'AFC',
        colSpan: 2,
        styles: headCellStyles
      },
      {
        value: 'Помилка',
        colSpan: 1,
        rowSpan: 2,
        styles: headCellStyles
      }
    ],
    [
      { value: 'Номер ТЗ', styles: headCellStyles },
      { value: 'Водій (ІД)', styles: headCellStyles },
      { value: 'Номер маршруту', styles: headCellStyles },
      { value: 'Номер ТЗ', styles: headCellStyles },
      { value: 'Номер маршруту', styles: headCellStyles }
    ],
    [
      { colSpan: 1, value: 'timestamp', styles: headCellStyles },
      { colSpan: 1, value: 'consoleBarcode', styles: headCellStyles },
      {
        colSpan: 1,
        value: 'avlVehicleRegistrationNumber',
        styles: headCellStyles
      },
      {
        colSpan: 1,
        value: 'driverPersonNumber',
        styles: headCellStyles
      },
      {
        colSpan: 1,
        value: 'lineRegistrationNumber',
        styles: headCellStyles
      },
      {
        colSpan: 1,
        value: 'afcVehicleRegistrationNumber',
        styles: headCellStyles
      },
      {
        colSpan: 1,
        value: 'lineRegistrationNumber',
        styles: headCellStyles
      },
      { colSpan: 1, value: 'error', styles: headCellStyles }
    ]
  ],
  [tableSemanticElements.BODY]: report?.map(reportEl => [
    {
      colSpan: 1,
      value: emptyDataPlaceholder(getReportDate(reportEl.timestamp)),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.consoleBarcode),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.avl.vehicleRegistrationNumber),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.avl.driverPersonNumber),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.avl.lineRegistrationNumber),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.afc.vehicleRegistrationNumber),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.afc.lineRegistrationNumber),
      styles: cellStyles
    },
    {
      colSpan: 1,
      value: emptyDataPlaceholder(reportEl.error),
      styles: cellStyles
    }
  ])
});
