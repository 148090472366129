import moment from 'moment';
import { DATE_FORMAT_SLASH } from 'src/config';
import { emptyDataPlaceholder } from 'src/utils/text';
import { ReportFilterData } from '../../types';
import { ReportTableConfig } from '../types';
import {
  Counteragent,
  Ride,
  Route,
  RoutePerformingReport,
  SummaryReport
} from './types';
import {
  cellStyles,
  headCellStyles,
  reportInfoCellStyles,
  summaryCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';

const getSummaryRow = (data: SummaryReport, withStyles = true) =>
  [
    { value: formatValue(data.totalRidesCount.toFixed(2)) },
    { value: formatValue(data.totalCompletedRidesCount.toFixed(2)) },
    { value: formatValue(data.ridesCompletenessRatio) },
    { value: formatValue(data.totalFailedRidesCount.toFixed(2)) },
    { value: formatValue(data.ridesFailureRatio), colSpan: 1 }
  ].map(row => ({
    ...row,
    styles: withStyles ? summaryCellStyles : {}
  }));

const createSummaryRow = (value: string, data: SummaryReport) => {
  const summaryRow = getSummaryRow(data);
  summaryRow.unshift({
    value,
    colSpan: 3,
    styles: { ...summaryCellStyles, halign: 'left' }
  });
  return summaryRow;
};

const transformRidesToTable = (rides: Ride[]) =>
  rides.map(ride => [
    { value: moment(ride.date).format(DATE_FORMAT_SLASH), styles: cellStyles },
    { value: formatValue(ride.licensePlate), styles: cellStyles },
    { value: formatValue(ride.departureNumber), styles: cellStyles },
    ...getSummaryRow(ride, false).map(cell => ({ ...cell, styles: cellStyles }))
  ]);

const transformRoutesToTable = (routes: Route[]) =>
  routes
    .map(route => {
      const routeTitleRow = [
        {
          value: route.route,
          colSpan: 8,
          styles: summaryCellStyles
        }
      ];
      const routeSummaryRow = createSummaryRow(
        `Всього по маршруту ${route.route}`,
        route
      );
      const routeRides = transformRidesToTable(route.rides);

      return [routeTitleRow, ...routeRides, routeSummaryRow];
    })
    .flat();

const transformCouteragentsToTable = (
  counteragents: Counteragent[]
): ReportTableConfig =>
  counteragents
    .map(counteragent => {
      const counteragentTitleRow = [
        {
          value: counteragent.counteragent,
          colSpan: 8,
          styles: summaryCellStyles
        }
      ];
      const counteragentSummaryRow = createSummaryRow(
        `Всього по перевізнику ${counteragent.counteragent}`,
        counteragent
      );
      const counteragentRoutes = transformRoutesToTable(counteragent.routes);

      return [
        counteragentTitleRow,
        ...counteragentRoutes,
        counteragentSummaryRow
      ];
    })
    .flat();

const formatValue = (value: string | number) => {
  const placeholderValue = emptyDataPlaceholder(value);
  if (placeholderValue === '0.00') {
    return '0';
  }

  return placeholderValue;
};

export const title = '7. Звіт про виконання маршруту';

export const getTableConfig = (
  filterData: ReportFilterData,
  report: RoutePerformingReport
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        value: title,
        colSpan: 8,
        styles: { ...reportInfoCellStyles, halign: 'center' }
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [
      {
        value: 'Дата з',
        styles: reportInfoCellStyles
      },
      {
        value: filterData.dateFrom,
        colSpan: 7,
        styles: reportInfoCellStyles
      }
    ],
    [
      {
        value: 'Дата до',
        styles: reportInfoCellStyles
      },
      {
        value: filterData.dateTo,
        colSpan: 7,
        styles: reportInfoCellStyles
      }
    ],
    []
  ],
  [tableSemanticElements.HEAD]: [
    [
      { value: 'Дата', styles: headCellStyles },
      { value: 'Транспортний засіб', styles: headCellStyles },
      { value: 'Випуск', styles: headCellStyles },
      { value: 'Заплановано їздок', styles: headCellStyles },
      { value: 'Виконано їздок', styles: headCellStyles },
      { value: 'Виконання їздок, %', styles: headCellStyles },
      { value: 'Не виконано їздок', styles: headCellStyles },
      { value: 'Невиконання їздок, %', styles: headCellStyles }
    ]
  ],
  [tableSemanticElements.BODY]: transformCouteragentsToTable(
    report.counteragents
  )
});

export const ROUTE_PERFORMING_HEADERS_ROWS_NUMBER = 5;
export const ROUTE_PERFORMING_COLS_NUMBER = 8;
