import * as XLSX from 'sheetjs-style';
import {
  getSummaryRowStyles,
  SummaryRow
} from 'src/utils/xlsx/getSummaryRowStyles';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { boldText, firstRowStyles, leftAligned } from '../const';
import {
  ROUTE_PERFORMING_COLS_NUMBER,
  ROUTE_PERFORMING_HEADERS_ROWS_NUMBER
} from './getTableConfig';
import { RoutePerformingReport, StyleBase } from './types';

export const getRoutePerformingReportSummaryRows = (
  report: RoutePerformingReport
) => {
  const tableRowTopOffset = ROUTE_PERFORMING_HEADERS_ROWS_NUMBER;

  const summaryRows = report.counteragents.reduce(
    (accum, counteragent) => {
      const counteragentTitleRow = accum.tableRowTopOffset + 1;

      const { rows, ridesRowsNumber } = counteragent.routes.reduce(
        (routeAcc, route) => {
          const routeTitleRow = routeAcc.ridesRowsNumber + 1;
          const routeSummaryRow = routeTitleRow + route.rides.length + 1;

          const newRows = routeAcc.rows.concat([
            { row: routeTitleRow },
            {
              row: routeSummaryRow,
              styles: {
                A: { s: styleAlignLeft }
              }
            }
          ]);

          return {
            rows: newRows,
            ridesRowsNumber: routeSummaryRow
          };
        },
        { rows: [] as SummaryRow[], ridesRowsNumber: counteragentTitleRow }
      );

      const counteragentSummaryRow = ridesRowsNumber + 1;

      const newAccum = accum.rows.concat([
        { row: counteragentTitleRow },
        {
          row: counteragentSummaryRow,
          styles: {
            A: { s: styleAlignLeft }
          }
        },
        ...rows
      ]);

      return {
        rows: newAccum,
        tableRowTopOffset: counteragentSummaryRow
      };
    },
    { rows: [] as SummaryRow[], tableRowTopOffset }
  );

  return summaryRows;
};

const styleAlignLeft = {
  ...leftAligned,
  font: {
    bold: true
  }
} as Record<string, object>;

const styleBase: StyleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText,
      ...styleAlignLeft
    }
  },
  A3: {
    s: {
      ...boldText,
      ...styleAlignLeft
    }
  },
  B2: {
    s: {
      ...boldText,
      ...styleAlignLeft
    }
  },
  B3: {
    s: {
      ...boldText,
      ...styleAlignLeft
    }
  },
  A5: {
    s: {
      ...boldText
    }
  },
  B5: {
    s: {
      ...boldText
    }
  },
  C5: {
    s: {
      ...boldText
    }
  },
  D5: {
    s: {
      ...boldText
    }
  },
  E5: {
    s: {
      ...boldText
    }
  },
  F5: {
    s: {
      ...boldText
    }
  },
  G5: {
    s: {
      ...boldText
    }
  },
  H5: {
    s: {
      ...boldText
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 4, c: 0 };

export const getStyles = (report: RoutePerformingReport) =>
  getStylesForReportWithTable(
    {
      ...styleBase,
      ...getSummaryRowStyles(
        getRoutePerformingReportSummaryRows(report).rows,
        ROUTE_PERFORMING_COLS_NUMBER
      )
    } as StyleBase,
    TABLE_START_ADDRESS
  );
