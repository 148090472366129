import { createAsyncAction } from 'typesafe-actions';
import { EquipmentTypeEnum } from 'src/constants';
import { Equipment } from '../types';

interface ValidationPayload {
  barcode: string;
  imei: string;
  equipmentId: number;
}

interface GetEquipmentsPayload {
  barcode: string;
  counteragentName: string;
  counteragentId: number;
}

interface GetUninstallationEquipmentsPayload {
  barcode: string;
  counteragentId: number;
  counteragentName: string;
  vehicleId: string;
  responsibleEmployeeId: string;
  equipmentType: EquipmentTypeEnum;
}

export const getEquipments = createAsyncAction(
  '@REPLACEMENT/GET_EQUIPMENTS_REQUEST',
  '@REPLACEMENT/GET_EQUIPMENTS_SUCCESS',
  '@REPLACEMENT/GET_EQUIPMENTS_FAILURE'
)<GetEquipmentsPayload, Equipment, Error>();

export const getUninstallationEquipments = createAsyncAction(
  '@REPLACEMENT/GET_UNINSTALLATION_EQUIPMENTS_REQUEST',
  '@REPLACEMENT/GET_UNINSTALLATION_EQUIPMENTS_SUCCESS',
  '@REPLACEMENT/GET_UNINSTALLATION_EQUIPMENTS_FAILURE'
)<GetUninstallationEquipmentsPayload, Equipment | void, Error>();

export const validateEquipment = createAsyncAction(
  '@REPLACEMENT/VALIDATE_EQUIPMENT_REQUEST',
  '@REPLACEMENT/VALIDATE_EQUIPMENT_SUCCESS',
  '@REPLACEMENT/VALIDATE_EQUIPMENT_FAILURE'
)<ValidationPayload, undefined, Error>();
