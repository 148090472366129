import { flatten } from 'lodash';
import moment from 'moment';
import { emptyDataPlaceholder } from 'src/utils/text';
import { DATE_FORMAT_FULL } from '../../../../../../../config';
import { SUMMARY_ROW_FILL_COLOR } from '../../../../../../../utils/xlsx/getSummaryRowStyles';
import { ReportFilterData } from '../../types';
import { ReportTableConfig } from '../types';
import {
  BusWorkOnRouteReport,
  CounteragentsEntity,
  DeparturesEntity,
  RoutesEntity,
  Summary
} from './types';
import { cellStyles, TableConfig, tableSemanticElements } from '../const';

const headers = [
  {
    label: 'Дата',
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Випуск',
    cellWidth: 9
  },
  {
    label: 'Зміна',
    cellWidth: 9
  },
  {
    label: 'Транспортний засіб',
    cellWidth: 14.5,
    colSpan: 2
  },
  {
    label: 'Водії',
    cellWidth: 14.5,
    colSpan: 2
  },
  {
    label: 'Час початку роботи на маршруті, год',
    children: ['план', 'факт'],
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Час закінчення роботи на маршруті, год',
    children: ['план', 'факт'],
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Тривалість роботи на маршруті, год',
    children: ['план', 'факт'],
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Кількість кіл',
    children: ['план', 'факт'],
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Кількість зупинок',
    children: ['план', 'факт', 'по графіку', 'в спецрежимі'],
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Пробіг, км',
    children: ['план', 'факт'],
    cellWidth: 12,
    colSpan: 2
  },
  {
    label: 'Повнота виконнання рейсів, %',
    colSpan: 2,
    cellWidth: 14.5
  },
  {
    label: 'Графічність, %',
    colSpan: 2,
    cellWidth: 14.5
  },
  {
    label: 'Відвідуваність зупинок, %',
    colSpan: 2,
    cellWidth: 14.5
  }
];

export const title = '1. Звіт про роботу транспортних засобів на маршруті';

export const BUS_WORK_ON_ROUTE_HEADERS_NUMBER = headers.reduce(
  (acc, header) => acc + (header.children?.length ?? 1),
  0
);

const headerStyles = {
  valign: 'middle',
  halign: 'center',
  fontStyle: 'bold'
} as const;

const subheaderStyles = {
  ...headerStyles,
  fillColor: SUMMARY_ROW_FILL_COLOR
} as const;

const summaryRowStyles = {
  fontStyle: 'bold',
  fillColor: SUMMARY_ROW_FILL_COLOR
};

const unPlannedValue = 'Не заплановано';

const generateSummaryRow = (summary: Summary, label: string) =>
  [
    {
      value: label,
      colSpan: 11,
      styles: {
        fillColor: SUMMARY_ROW_FILL_COLOR,
        halign: 'left'
      }
    },
    { value: summary.numberOfRides.planned },
    { value: summary.numberOfRides.fact },
    { value: summary.visitedBusStops.planned },
    { value: summary.visitedBusStops.fact },
    { value: summary.visitedBusStops.onSchedule },
    { value: summary.visitedBusStops.inSpecialMode },
    { value: summary.distanceOnRoute.planned },
    { value: summary.distanceOnRoute.fact },
    { value: summary.completeness },
    { value: summary.graphicity },
    { value: summary.attendance }
  ].map(val => ({
    ...val,
    styles: { ...summaryRowStyles, ...cellStyles, ...val.styles }
  }));

const generateData = ({
  startOfWorkTime,
  endOfWorkTime,
  durationOfWorkTime,
  summary
}: DeparturesEntity): (number | string)[] => {
  // fix for those two fields possible being null
  const factStartOfWorkTime = startOfWorkTime.fact?.toString() || '';
  const factEndOfWorkTime = endOfWorkTime.fact?.toString() || '';
  const factDuration = durationOfWorkTime.fact?.toString() || '';

  return [
    startOfWorkTime.planned.toString(),
    factStartOfWorkTime,
    endOfWorkTime.planned.toString(),
    factEndOfWorkTime,
    durationOfWorkTime.planned.toString(),
    factDuration,
    summary.numberOfRides.planned,
    summary.numberOfRides.fact,
    summary.visitedBusStops.planned,
    summary.visitedBusStops.fact,
    summary.visitedBusStops.onSchedule,
    summary.visitedBusStops.inSpecialMode,
    summary.distanceOnRoute.planned,
    summary.distanceOnRoute.fact,
    summary.completeness,
    summary.graphicity,
    summary.attendance
  ];
};

const generateDepartureRows = (departures: DeparturesEntity[]) =>
  departures
    .map(departure =>
      departure.workShifts.map((workShift, index) => [
        ...(index === 0
          ? [
              {
                value: moment(departure.date).format(DATE_FORMAT_FULL),
                rowSpan: departure.workShifts.length,
                styles: cellStyles
              },
              {
                value: emptyDataPlaceholder(departure.departureSequenceNumber),
                rowSpan: departure.workShifts.length,
                styles: cellStyles
              }
            ]
          : []),
        { value: emptyDataPlaceholder(workShift.name), styles: cellStyles },
        { value: workShift.licensePlate ?? unPlannedValue, styles: cellStyles },
        { value: workShift.driver ?? unPlannedValue, styles: cellStyles },
        ...(index === 0
          ? generateData(departure).map(value => ({
              rowSpan: departure.workShifts.length,
              value: emptyDataPlaceholder(value),
              styles: cellStyles
            }))
          : [])
      ])
    )
    .flat();

const generateRouteRows = (routes: RoutesEntity[]) =>
  routes
    .map(({ route, departures, summary }) => [
      [
        {
          value: `Маршрут: ${route}`,
          colSpan: BUS_WORK_ON_ROUTE_HEADERS_NUMBER,
          styles: subheaderStyles
        }
      ],
      ...generateDepartureRows(departures),
      generateSummaryRow(summary, `Всього по маршруту ${route}`)
    ])
    .flat();

const generateCounteragentRows = (counteragents: CounteragentsEntity[]) =>
  counteragents
    .map(({ routes, counteragent, summary }) => [
      [
        {
          value: counteragent,
          colSpan: BUS_WORK_ON_ROUTE_HEADERS_NUMBER,
          styles: subheaderStyles
        }
      ],
      ...generateRouteRows(routes),
      generateSummaryRow(summary, `Всього по перевізнику ${counteragent}`)
    ])
    .flat();

export const getTableConfig = (
  filterData: ReportFilterData,
  report: BusWorkOnRouteReport
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        value: title,
        colSpan: 22,
        styles: { ...headerStyles, lineColor: 'white' }
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [
      {
        value: 'За період:',
        colSpan: 2,
        styles: {
          ...headerStyles,
          lineColor: 'white',
          halign: 'left'
        }
      },
      {
        value: `${filterData.dateFrom} - ${filterData.dateTo}`,
        colSpan: 20,
        styles: {
          lineColor: 'white',
          halign: 'left',
          fontStyle: 'bold'
        }
      }
    ],
    []
  ],
  [tableSemanticElements.HEAD]: [
    headers.map(header => ({
      value: header.label,
      rowSpan: header.children?.length ? 1 : 2,
      colSpan: header.children?.length || 1,
      styles: { ...headerStyles, cellWidth: header.cellWidth }
    })),
    flatten(
      headers
        .filter(header => header.children !== undefined)
        .map(header =>
          header.children!.map(child => ({
            value: child,
            styles: {
              ...headerStyles,
              cellWidth: header.cellWidth,
              colSpan: header.colSpan
            }
          }))
        )
    )
  ],
  [tableSemanticElements.BODY]: generateCounteragentRows(
    report.counteragents
  ) as ReportTableConfig
});
