import moment from 'moment';

const DATE_FORMAT = 'DD.MM.YYYY';

export const getRouteName = ({
  number,
  name,
  contractStartDate,
  contractEndDate
}: RoutesOption) => {
  let dateToAdd = '';

  if (contractStartDate) {
    dateToAdd = `, ${moment(contractStartDate).format(DATE_FORMAT)}`;
    if (contractEndDate) {
      dateToAdd += ` - ${moment(contractEndDate).format(DATE_FORMAT)}`;
    }
  }

  return number ? `${number}, ${name}${dateToAdd}` : '';
};

export interface RoutesOption extends AutocompleteOption {
  number?: string;
  contractStartDate?: string;
  contractEndDate?: string;
}
