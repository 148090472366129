import { KPI_PARAMETERS_GROUPS } from 'src/config/clearingUrls';
import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import { TableConfig } from 'src/components/Tables/TableWithPagination/types';
import { crudActions, KpiParametersState } from '../const';
import KpiStateLabel from './KpiStateLabel';

export const columns = [
  {
    label: 'Назва групи параметрів',
    value: 'name',
    sortable: true,
    width: '30%'
  },
  {
    label: 'Період дії',
    value: 'effectiveDate',
    sortable: true,
    width: '35%'
  },
  {
    label: 'Стан',
    value: 'state',
    sortable: true,
    width: '25%',
    changeValue: ({ state }: Row) => <KpiStateLabel state={state} />
  }
];

export const KPI_PARAMETERS_TABLE_PATH = 'clearing.kpiParameters.table';
export const KPI_PARAMETERS_TABLE_NAME = 'KPI_PARAMETERS';

export const KPI_PARAMETERS_TABLE_CONFIG: TableConfig = {
  action: crudActions.create,
  reducerName: KPI_PARAMETERS_TABLE_NAME,
  reducerPath: KPI_PARAMETERS_TABLE_PATH,
  apiUrl: KPI_PARAMETERS_GROUPS,
  routeUrl: '/clearing/kpiParameters/',
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: (row: Row) => row.state === KpiParametersState.Future,
      action: crudActions.update,
      disabledTooltip: 'Неможливо редагувати'
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) =>
        row.state === KpiParametersState.Future && row.canDelete,
      action: crudActions.delete,
      disabledTooltip: 'Неможливо видалити'
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  },
  isRowClickEnabled: true
};
