import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_PASSPORT } from 'src/config/avlUrls';
import { navigateTo } from 'src/store/actions/app';
import { selectPassportId } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import * as actions from './actions';

export function* createTimetableSaga({
  payload
}: ReturnType<typeof actions.createTimetable.request>): SagaIterator {
  const passportId = yield select(selectPassportId);
  const { data: timetableId } = yield call(
    apiClient.post,
    `${ROUTE_PASSPORT}/${passportId}/route-timetables`,
    payload,
    {
      retryAction: actions.createTimetable.request(payload)
    }
  );
  yield put(
    navigateTo({
      url: `/avl/routes/create/timetable/${passportId}/create/${timetableId}`
    })
  );
  yield put(
    enqueueSnackbar({
      key: 'timetable_create',
      message: 'Графік створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createTimetable.success());
}

export default function* PassportCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createTimetable.request),
    safe(createTimetableSaga, actions.createTimetable.failure)
  );
}
