import * as Yup from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { NAV_BAR_HEADERS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export const editDisabledTooltip =
  'Неможливо редагувати. Несправність використовується';

export const deleteDisabledTooltip =
  'Неможливо видалити. Несправність використовується';

export const listBreadcrumbs = [
  {
    text: NAV_BAR_HEADERS.equipment,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: 'Типи несправностей',
    url: '/asset/dictionaries/breakdownsTypes'
  }
];

export const crudActions = generateActionCrud({
  module: 'assets',
  sub: 'breakdownsTypes'
});

export const breakdownsTypesValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  state: Yup.string().required(ERROR_MESSAGES.REQUIRED)
});

export const isDeleteDisabledText = (isDeleteDisabled: boolean | undefined) =>
  isDeleteDisabled ? deleteDisabledTooltip : 'Видалити';

export const isEditDisabledText = (isEditDisabled: boolean | undefined) =>
  isEditDisabled ? editDisabledTooltip : 'Редагувати';
