import { Styles } from 'jspdf-autotable';
import { flatten } from 'lodash';
import { ReportFilterData } from '../../types';
import { TableRow } from '../types';
import { MorningEveningRideData, MorningEveningRidesReport } from './types';
import { getMorningEveningRidesColumns } from './utils';
import { TableConfig, tableSemanticElements } from '../const';

const reportDetailsStyles: Partial<Styles> = {
  lineColor: 'white',
  halign: 'left',
  fontStyle: 'bold'
};

const headers = [
  {
    label: 'Маршрут'
  },
  {
    label: 'Випуск'
  },
  {
    label: 'Транспортний засіб'
  },
  {
    label: 'Зупинка'
  },
  {
    label: 'Початок роботи, год',
    children: ['план', 'факт']
  },
  {
    label: 'Закінчення роботи, год',
    children: ['план', 'факт']
  }
];

export const title = '6. Звіт про виконання ранкових та вечірніх рейсів';

type ReportData = Record<string, MorningEveningRideData[]>;

const generateData = (rides: MorningEveningRidesReport): TableRow[] => {
  const data: ReportData = {};

  rides.forEach(ride => {
    if (!data[ride.routeName]) {
      data[ride.routeName] = [];
    }

    data[ride.routeName].push({
      departureName: ride.departureName,
      licensePlate: ride.licensePlate || 'Не заплановано',
      busStopName: ride.busStopName,
      busStopCode: ride.busStopCode,
      departure: ride.departure,
      arrival: ride.arrival
    });
  });

  const rows: TableRow[] = [];

  Object.keys(data).forEach(routeName => {
    const columns = getMorningEveningRidesColumns(data[routeName]);

    columns[0].unshift({
      value: routeName,
      rowSpan: data[routeName].length,
      styles: {
        halign: 'center',
        valign: 'middle'
      }
    });

    columns.forEach((row, rowIndex) => {
      columns[rowIndex] = row.map(cell => ({
        ...cell,
        styles: {
          ...(cell.styles || {}),
          halign: 'center',
          valign: 'middle'
        }
      }));
    });

    rows.push(...columns);
  });

  return rows;
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: MorningEveningRidesReport
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        value: title,
        colSpan: 7,
        styles: { ...reportDetailsStyles, halign: 'center' }
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [],
    [
      { value: 'Перевізник', styles: reportDetailsStyles },
      {
        value: filterData.counteragent,
        colSpan: 6,
        styles: reportDetailsStyles
      }
    ],
    [
      { value: 'Дата', styles: reportDetailsStyles },
      {
        value: filterData.date,
        colSpan: 6,
        styles: reportDetailsStyles
      }
    ],
    []
  ],
  [tableSemanticElements.HEAD]: [
    headers.map(header => ({
      value: header.label,
      rowSpan: header.children?.length ? 1 : 2,
      colSpan: header.children?.length || 1,
      styles: {
        fontStyle: 'bold',
        halign: 'center',
        valign: 'middle'
      }
    })),
    flatten(
      headers
        .filter(header => header.children !== undefined)
        .map(header =>
          header.children!.map(child => ({
            value: child,
            styles: {
              fontStyle: 'bold',
              halign: 'center',
              valign: 'middle'
            }
          }))
        )
    )
  ],
  [tableSemanticElements.BODY]: generateData(report)
});
