import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import {
  Box,
  CircularProgress,
  TextField,
  TextFieldProps
} from '@material-ui/core';
import { FastField, FastFieldProps, useField } from 'formik';
import EditableSelectItem from 'src/components/EditableSelect/EditableSelectItem';
import { getAutocompleteOptionsRequest } from './store/actions';
import { selectAutocompleteOptions, selectLoading } from './store/selectors';
import { AutocompleteConfig, FilterOptions } from './types';

interface Props<T extends object = {}> {
  name: string;
  label: string;
  filterByField?: string;
  config: AutocompleteConfig;
  nativeProps?: Partial<
    AutocompleteProps<AutocompleteOption<T>, boolean, boolean, boolean>
  >;
  textFieldNativeProps?: TextFieldProps;
  filterOptions?: FilterOptions<T>;
  required?: boolean;
  renderOption?: (option: AutocompleteOption<T>) => React.ReactNode;
}

type MemoisedProps<T extends object = {}> = FastFieldProps & Props<T>;

enum NoOptionsText {
  'nothingFound' = 'Нічого не знайдено',
  'pleaseEnterSearchValue' = 'Введіть пошуковий запит',
  'loading' = 'Завантаження...'
}

export const MemoisedAutocomplete = <T extends object = {}>(
  props: Props<T>
) => (
  <FastField name={props.name}>
    {(field: FastFieldProps) => <FieldAutocomplete {...props} {...field} />}
  </FastField>
);

const FieldAutocomplete = <T extends object = {}>({
  name: fieldName,
  label,
  config,
  nativeProps = {},
  textFieldNativeProps,
  filterOptions,
  required,
  renderOption,
  field,
  meta,
  form
}: MemoisedProps<T>) => {
  const { optionsApiUrl, prohibitInitialLoad } = config;
  const { onChange, onClose, onOpen, ...restNativeProps } = nativeProps;
  const dispatch = useDispatch();

  const options = useSelector(
    selectAutocompleteOptions<T>(config.reducerPath, filterOptions)
  );
  const loading = useSelector(selectLoading(config.reducerPath));
  const helperText = meta.touched && meta.error;

  const [open, setOpen] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState(
    NoOptionsText.pleaseEnterSearchValue
  );

  useEffect(() => {
    if (!prohibitInitialLoad && optionsApiUrl) {
      dispatch(getAutocompleteOptionsRequest(config));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsApiUrl, prohibitInitialLoad]);

  const handleRenderOption = (option: AutocompleteOption<T>) => {
    const customOption = renderOption && renderOption(option);

    return customOption || <EditableSelectItem name={option.name} />;
  };

  return (
    <Autocomplete<AutocompleteOption<T>, boolean, boolean, boolean>
      open={open}
      value={field.value}
      options={options}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={({ name }) => name}
      noOptionsText={noOptionsText}
      clearText="Очистити"
      onChange={(e, value, ...rest) => {
        form.setFieldValue(fieldName, value);
        onChange && onChange(e, value, ...rest);
      }}
      onOpen={e => {
        setOpen(true);
        onOpen && onOpen(e);
      }}
      onClose={(e, reason) => {
        setOpen(false);
        onClose && onClose(e, reason);
      }}
      renderOption={handleRenderOption}
      loading={loading}
      loadingText={NoOptionsText.loading}
      {...restNativeProps}
      renderInput={params => (
        <TextField
          {...params}
          {...field}
          label={label}
          required={required}
          error={!!helperText}
          helperText={helperText}
          variant="outlined"
          fullWidth
          onChange={e => {
            if (e.target.value) {
              setNoOptionsText(NoOptionsText.nothingFound);
            } else {
              setNoOptionsText(NoOptionsText.pleaseEnterSearchValue);
            }
            !config.prohibitDynamicLoad &&
              dispatch(
                getAutocompleteOptionsRequest(config, {
                  filter: e.target.value
                })
              );
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment:
              open && loading ? (
                <Box position="absolute" right={12} bottom={14}>
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                params.InputProps.endAdornment
              )
          }}
          {...textFieldNativeProps}
        />
      )}
    />
  );
};

const FormAutocomplete = <T extends object = {}>({
  name: fieldName,
  label,
  filterByField,
  config,
  nativeProps = {},
  textFieldNativeProps,
  filterOptions,
  required,
  renderOption
}: Props<T>) => {
  const { optionsApiUrl, prohibitInitialLoad } = config;
  const [field, { error, touched }, { setValue }] = useField(fieldName);
  const { onChange, onClose, onOpen, ...restNativeProps } = nativeProps;
  const dispatch = useDispatch();

  const options = useSelector(
    selectAutocompleteOptions<T>(config.reducerPath, filterOptions)
  );
  const loading = useSelector(selectLoading(config.reducerPath));
  const helperText = touched && error;

  const [open, setOpen] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState(
    NoOptionsText.pleaseEnterSearchValue
  );

  useEffect(() => {
    if (!prohibitInitialLoad && optionsApiUrl) {
      dispatch(
        getAutocompleteOptionsRequest(config, {
          filter: field.value?.name || ''
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsApiUrl, prohibitInitialLoad]);

  const handleRenderOption = (option: AutocompleteOption<T>) => {
    const customOption = renderOption && renderOption(option);

    return customOption || <EditableSelectItem name={option.name} />;
  };

  const getOptionSelected = (
    option: AutocompleteOption,
    value: AutocompleteOption
  ) => {
    const optionKey: keyof AutocompleteOption = 'id' in option ? 'id' : 'name';

    return option[optionKey] === value[optionKey];
  };

  return (
    <Autocomplete<AutocompleteOption<T>, boolean, boolean, boolean>
      open={open}
      value={field.value}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={({ name }) => name}
      noOptionsText={noOptionsText}
      clearText="Очистити"
      onChange={(e, value, ...rest) => {
        setValue(value);
        onChange && onChange(e, value, ...rest);
      }}
      onOpen={e => {
        setOpen(true);
        onOpen && onOpen(e);
      }}
      onClose={(e, reason) => {
        setOpen(false);
        onClose && onClose(e, reason);
      }}
      renderOption={handleRenderOption}
      loading={loading}
      loadingText={NoOptionsText.loading}
      {...restNativeProps}
      renderInput={params => (
        <TextField
          {...params}
          {...field}
          label={label}
          required={required}
          error={!!helperText}
          helperText={helperText}
          variant="outlined"
          fullWidth
          onChange={e => {
            if (e.target.value) {
              setNoOptionsText(NoOptionsText.nothingFound);
            } else {
              setNoOptionsText(NoOptionsText.pleaseEnterSearchValue);
            }
            !config.prohibitDynamicLoad &&
              dispatch(
                getAutocompleteOptionsRequest(config, {
                  filter: filterByField ? '' : e.target.value,
                  ...(filterByField && {
                    queryParams: {
                      [filterByField]: e.target.value
                    }
                  })
                })
              );
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment:
              open && loading ? (
                <Box position="absolute" right={12} bottom={14}>
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                params.InputProps.endAdornment
              )
          }}
          {...textFieldNativeProps}
        />
      )}
    />
  );
};

FormAutocomplete.defaultProps = {
  nativeProps: {},
  textFieldNativeProps: {
    variant: 'outlined',
    fullWidth: true
  },
  filterOptions: undefined
};

export default FormAutocomplete;
