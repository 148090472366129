import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import {
  IMPORT_DEPARTURE,
  ROUTE_DEPARTURES,
  ROUTE_PASSPORT
} from 'src/config/avlUrls';
import { Error as ValidationError } from 'src/utils/validation';
import { getDataRequest } from 'src/components/Tables/TableView/store/actions';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';
import { selectRouteParams } from '../../../../RoutesPassportView/store/selectors';
import { selectPassportSettings } from '../../Settings/SettingsDetailsView/store/selectors';
import { DEPARTURE_TABLE_CONFIG } from '../const';
import { getErrorText } from '../Departures/DeparturesForm/utils';

export function* getTimetablePdfData({
  payload: timetableId
}: ReturnType<typeof actions.getTimetablePdfData.request>): SagaIterator {
  const { id } = yield select(selectRouteParams);
  const { generalSettings } = yield select(selectPassportSettings);
  const { data } = yield call(
    apiClient.get,
    `${ROUTE_PASSPORT}/${id}/route-timetables/${timetableId}/export`,
    {
      retryAction: actions.getTimetablePdfData.request
    }
  );

  yield put(
    actions.getTimetablePdfData.success({
      ...data,
      routeNumber: generalSettings.number
    })
  );
}

export function* importDeparture({
  payload
}: ReturnType<typeof actions.importDeparture.request>): SagaIterator {
  const { id, subId } = yield select(selectRouteParams);

  try {
    const { data: newSubId } = yield call(
      apiClient.post,
      IMPORT_DEPARTURE(subId),
      payload,
      {
        retryAction: actions.importDeparture.request(payload),
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    const isChanged = Number(subId) !== Number(newSubId);

    let message = 'Випуск додано успішно.';

    if (isChanged) {
      message += 'Затвердіть новий графік';
    }

    yield put(
      enqueueSnackbar({
        key: 'import_success',
        message,
        options: {
          variant: 'success'
        }
      })
    );

    yield put(actions.importDeparture.success());

    if (isChanged) {
      yield put(
        navigateTo({
          url: `/avl/routes/create/timetable/${id}/details/${newSubId}`
        })
      );
    }
  } catch (e) {
    const errorMessages = (e as ValidationError[])
      .map(error => getErrorText(error))
      .join('; ');

    const message = `Не вдалося завантажити, перевірте правильність даних в файлі. Помилки: ${errorMessages}`;

    yield put(
      enqueueSnackbar({
        key: 'import_failed',
        message,
        options: {
          variant: 'error'
        }
      })
    );
    yield put(actions.importDeparture.failure(e as Error));
  }

  yield put(
    getDataRequest({
      ...DEPARTURE_TABLE_CONFIG,
      apiUrl: ROUTE_DEPARTURES(subId)
    })
  );
}

export default function* RoutesTimetableSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getTimetablePdfData.request),
      safe(getTimetablePdfData, actions.getTimetablePdfData.failure)
    ),
    takeEvery(getType(actions.importDeparture.request), importDeparture)
  ]);
}
