import { flatten } from 'lodash';
import moment from 'moment';
import { DETAILED_DATE_FORMAT, DETAILED_TIME_FORMAT } from 'src/config';
import { convertSecondsToTimestamp } from 'src/utils/date';
import { emptyDataPlaceholder } from 'src/utils/text';
import { ReportFilterData } from '../../types';
import {
  cellStyles,
  headCellStyles,
  reportInfoCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';
import { ReportTableConfig } from '../types';
import { GraphicityPerformance, ReportBusStop, Vehicle } from './types';

export const title =
  '5. Звіт про відвідування зупинок та виконання графічності';

const formatDeviation = (deviationInSec: number) => {
  if (!deviationInSec) return '-';

  return (
    (deviationInSec > 0 ? '+' : '-') +
    convertSecondsToTimestamp(deviationInSec, 'HH:mm')
  );
};

const formatBusStop = (
  busStop: ReportBusStop,
  departureName = '',
  iterationNumber: number,
  vehicle: Vehicle
) => {
  const timeFields = [
    'plannedDateArrive',
    'plannedDateDeparture',
    'factDateArrive',
    'factDateDeparture'
  ];
  const busStopFieldOrder: (keyof (ReportBusStop & {
    busStopName: string;
  }))[] = [
    'departureName',
    'iterationNumber',
    'vehicleLicensePlate',
    'busStopName',
    'plannedDateArrive',
    'plannedDateDeparture',
    'factDateArrive',
    'factDateDeparture',
    'deviationInSec',
    'notes'
  ];

  return busStopFieldOrder.map(field => {
    const value = busStop[field];

    if (field === 'departureName') {
      return {
        value: emptyDataPlaceholder(departureName),
        styles: cellStyles
      };
    }

    if (field === 'iterationNumber') {
      return {
        value: emptyDataPlaceholder(iterationNumber),
        styles: cellStyles
      };
    }

    if (field === 'vehicleLicensePlate') {
      return {
        value: emptyDataPlaceholder(vehicle.vehicleLicensePlate),
        styles: cellStyles
      };
    }

    if (field === 'busStopName') {
      return {
        value: emptyDataPlaceholder(busStop.busStopName),
        styles: cellStyles
      };
    }

    if (field === 'deviationInSec') {
      return {
        value: emptyDataPlaceholder(formatDeviation(busStop.deviationInSec)),
        styles: cellStyles
      };
    }

    if (timeFields.includes(field)) {
      return {
        value: value
          ? moment(value as string, DETAILED_DATE_FORMAT).format(
              DETAILED_TIME_FORMAT
            )
          : '-',
        styles: cellStyles
      };
    }

    return {
      value: emptyDataPlaceholder(value),
      styles: cellStyles
    };
  });
};

const convertRide = (report: GraphicityPerformance) =>
  flatten(
    report.map(ride => {
      const { vehicles, iterationNumber, departureName } = ride;

      const result: ReportTableConfig = [];

      vehicles.forEach((vehicle, vehicleIndex) => {
        const busStopValues: ReportTableConfig = [];

        vehicle.busStops.forEach((busStop, busStopIndex) => {
          // Array of values for bus stop { value: '' }
          const formattedBusStop = formatBusStop(
            busStop,
            departureName,
            iterationNumber,
            vehicle
          );

          // Vehicle with 0 index should also contain { value: sequenceNumber }
          // Bus stop with 0 index should also contain { value: licensePlate }

          if (vehicleIndex === 0) {
            if (busStopIndex === 0) {
              const newValue = [...formattedBusStop];
              busStopValues.push(newValue);
            } else {
              busStopValues.push(formattedBusStop);
            }
          } else if (busStopIndex === 0) {
            const newValue = [
              {
                value: vehicle.vehicleLicensePlate,
                rowSpan: vehicle.busStops.length,
                styles: cellStyles
              },
              ...formattedBusStop
            ];
            busStopValues.push(newValue);
          } else {
            busStopValues.push(formattedBusStop);
          }
        });

        result.push(...busStopValues);
      });
      return result;
    })
  );

export const getTableConfig = (
  filterData: ReportFilterData,
  report: GraphicityPerformance
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        value: title,
        colSpan: 10,
        styles: reportInfoCellStyles
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [
      {
        value: 'Перевізник',
        styles: { ...reportInfoCellStyles, halign: 'left' }
      },
      {
        value: filterData.counteragent,
        colSpan: 9,
        styles: { ...reportInfoCellStyles, halign: 'left' }
      }
    ],
    [
      {
        value: 'Маршрут',
        styles: { ...reportInfoCellStyles, halign: 'left' }
      },
      {
        value: `${filterData.routeNumber}, ${filterData.route}`,
        colSpan: 9,
        styles: { ...reportInfoCellStyles, halign: 'left' }
      }
    ],
    [
      { value: 'Дата', styles: { ...reportInfoCellStyles, halign: 'left' } },
      {
        value: filterData.date,
        colSpan: 9,
        styles: { ...reportInfoCellStyles, halign: 'left' }
      }
    ],
    []
  ],
  [tableSemanticElements.HEAD]: [
    [
      { value: 'Випуск' },
      { value: 'Їздка' },
      { value: 'Транспортний засіб' },
      { value: 'Зупинка' },
      { value: 'Плановий час прибуття' },
      { value: "Плановий час від'їзду" },
      { value: 'Фактичний час прибуття' },
      { value: "Фактичний час від'їзду" },
      { value: 'Відхилення від графіку' },
      { value: 'Примітки' }
    ].map(cell => ({
      ...cell,
      styles: headCellStyles
    }))
  ],
  [tableSemanticElements.BODY]: convertRide(report)
});
