import { COUNTERAGENTS } from 'src/config/avlUrls';
import { Row } from 'src/components/Tables/types';
import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { BILLING_DOCUMENTS } from 'src/config/clearingUrls';
import { Protected } from 'src/components/Guards';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import { crudActions } from '../const';
import BillingDocumentsCalculatorIcon from '../helpers/BillingDocumentsCalculatorIcon';
import BillingDocumentType from '../helpers/BillingDocumentType';
import BillingDocumentsGPSIcon from '../helpers/BillingDocumentsGPSIcon';
import BillingDocumentsSwitch from '../BillingDocumentsSwitch/BillingDocumentsSwitch';
import { BILLING_DOCUMENT_CONFIG } from '../BillingDetailsListView/const';

export const BILLING_DOCUMENTS_TABLE_PATH = 'clearing.billingDocuments.table';
export const BILLING_DOCUMENTS_TABLE_NAME = 'BILLING_DOCUMENTS';

export const TABLE_CONFIG_BASE = {
  reducerPath: BILLING_DOCUMENTS_TABLE_PATH,
  reducerName: BILLING_DOCUMENTS_TABLE_NAME,
  apiUrl: BILLING_DOCUMENTS,
  routeUrl: '/clearing/billing-documents/',
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  }
};

export const columns = [
  {
    label: 'Дата документа',
    value: 'documentDate',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Назва документа',
    value: 'documentName',
    sortable: true,
    width: '11%'
  },
  {
    label: 'За період',
    value: 'dateFrom',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => `${row.dateFrom} - ${row.dateTo}`
  },
  {
    label: 'Перевізник',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Сума, грн',
    value: 'totalRewardAmount',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <BillingDocumentsCalculatorIcon row={row} />
  },
  {
    label: 'Нарахування',
    value: 'type',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <BillingDocumentType row={row} />
  },
  {
    label: '',
    value: 'hasGapsInTrack',
    sortable: false,
    width: '5%',
    changeValue: (row: Row) =>
      row.hasGapsInTrack ? <BillingDocumentsGPSIcon /> : <></>
  },
  {
    label: '',
    value: '',
    sortable: false,
    width: '5%',
    changeValue: (row: Row) => (
      <Protected action={crudActions.billingDocumentsAllowEditing}>
        <BillingDocumentsSwitch
          id={row.id}
          tableConfig={TABLE_CONFIG_BASE}
          status={row.status}
          disabled={row.isLocked}
        />
      </Protected>
    )
  }
];

export const filters = [
  {
    type: FilterType.Date,
    name: 'documentDate',
    label: 'Дата'
  },
  {
    name: 'counteragentId',
    label: 'Перевізник',
    optionsURL: `${COUNTERAGENTS}?withAprovedRoutePassport=true`
  },
  {
    name: 'id',
    label: 'Назва документа',
    type: FilterType.Autocomplete,
    autocompleteConfig: BILLING_DOCUMENT_CONFIG
  }
];

export const MIN_PAGE_SIZE = 1000;
