import * as XLSX from 'sheetjs-style';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import {
  boldText,
  centered,
  firstRowStyles,
  leftAligned,
  summaryRowStyles
} from '../const';
import { StyleBase } from '../RoutePerforming/types';
import { getTableHeadersCount } from './getTableConfig';
import { HourlyDeparture } from './types';

const styleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...leftAligned,
      ...boldText
    }
  },
  B2: {
    s: {
      ...leftAligned,
      ...boldText
    }
  },
  A4: {
    s: {
      ...boldText
    }
  },
  B4: {
    s: {
      ...boldText
    }
  },
  A7: {
    s: {
      ...boldText,
      ...summaryRowStyles,
      alignment: {
        horizontal: 'center',
        vertical: 'center'
      }
    }
  }
} as const;

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 3, c: 0 };

const getHeaderStyles = (report: HourlyDeparture) => {
  const timeStamps = report.counteragents[0]?.routes[0]?.timeSpans || [];
  const styles: StyleBase = {};
  const timeStampsRow = 5;
  const planFactRow = 6;

  for (let i = 1; i <= timeStamps.length * 2; i++) {
    const columnName = XLSX.utils.encode_col(i);
    styles[`${columnName}${timeStampsRow}`] = {
      s: {
        ...boldText
      }
    };
    styles[`${columnName}${planFactRow}`] = {
      s: {
        ...boldText
      }
    };
  }

  return styles;
};

export const getStyles = (report: HourlyDeparture) => {
  // first counteragent row
  let offset = 7;
  const lastColumnName = XLSX.utils.encode_col(
    getTableHeadersCount(report) - 1
  );

  const styles = report.counteragents.reduce<StyleBase>(
    (acc, counteragent) => {
      offset += counteragent.routes.length + 1;
      const summaryByCounteragentCell = `A${offset}`;
      const departureRatioCell = `${lastColumnName}${offset}`;

      offset += 1;
      const nextCounteragentCell = `A${offset}`;

      return {
        ...acc,
        [summaryByCounteragentCell]: {
          s: {
            ...boldText,
            ...leftAligned,
            ...summaryRowStyles
          }
        },
        [departureRatioCell]: {
          s: {
            ...boldText,
            ...summaryRowStyles,
            alignment: {
              horizontal: 'center',
              vertical: 'center'
            }
          }
        },
        [nextCounteragentCell]: {
          s: {
            ...boldText,
            ...centered,
            ...summaryRowStyles
          }
        }
      };
    },
    { ...styleBase }
  );

  styles[`${lastColumnName}4`] = {
    s: {
      ...boldText
    }
  };

  return getStylesForReportWithTable(
    { ...styles, ...getHeaderStyles(report) },
    TABLE_START_ADDRESS
  );
};
