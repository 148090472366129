import { sortBy } from 'lodash';
import { extractNumericPart } from 'src/utils/extractNumericPart';
import { ReportFilterData } from '../../types';
import {
  cellStyles,
  headCellStyles,
  reportInfoCellStyles,
  summaryCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';
import { TableRow } from '../types';
import { Counteragent, Route, WorkOfCarrierReport } from './types';

const transformRoutes = (routes: Route[]) =>
  routes.map(route =>
    [
      {
        value: route.routeName
      },
      {
        value: route.release.ratio
      },
      {
        value: route.presence.ratio
      },
      {
        value: route.summary.numberOfRides.ratio
      },
      {
        value: route.summary.graphicity
      }
    ].map(cell => ({ ...cell, styles: cellStyles }))
  );

export const title = '4. Звіт про роботу перевізника';

export const getCounteragentSection = (
  counteragent: Counteragent
): TableRow[] => {
  const routesSorted = sortBy(counteragent.routes, r =>
    extractNumericPart(r.routeName)
  );

  return [
    [
      {
        colSpan: 5,
        value: counteragent.counteragentName,
        styles: summaryCellStyles
      }
    ],
    ...transformRoutes(routesSorted),
    [
      {
        value: `Всього по перевізнику ${counteragent.counteragentName}`,
        styles: { ...summaryCellStyles, halign: 'left' }
      },
      { value: counteragent.release.ratio, styles: summaryCellStyles },
      { value: counteragent.presence.ratio, styles: summaryCellStyles },
      {
        value: counteragent.summary.numberOfRides.ratio,
        styles: summaryCellStyles
      },
      { value: counteragent.summary.graphicity, styles: summaryCellStyles }
    ]
  ];
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: WorkOfCarrierReport
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        value: title,
        colSpan: 5,
        styles: { ...reportInfoCellStyles, halign: 'center' }
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [],
    [
      { value: 'Дата', styles: reportInfoCellStyles },
      { value: filterData.date, colSpan: 4, styles: reportInfoCellStyles }
    ] as TableRow,
    []
  ],
  [tableSemanticElements.HEAD]: [
    [
      { value: 'Маршрут' },
      { value: 'Випуск на маршрут, %' },
      { value: 'Присутність на маршруті, %' },
      { value: 'Виконання їздок, %' },
      { value: 'Графічність, %  ' }
    ].map(cell => ({
      ...cell,
      styles: headCellStyles
    }))
  ],
  [tableSemanticElements.BODY]: report.counteragents
    .map(getCounteragentSection)
    .flat()
});
