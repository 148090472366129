import { ERROR_MESSAGES } from 'src/constants/validation';

export const DEPARTURES_ERRORS: Record<string, string> = {
  tooHighSpeedIsRequiredInDeparture:
    'Перевищено максимально допустиму швидкість. Перевірте час проїзду між зупинками',
  invalidRouteDepartureDuration: ERROR_MESSAGES.TYPE_ERROR,
  depotDepartureTimeEqualWorkPointTime:
    'Час виїзду з депо не може співпадати з часом зупинки',
  depotArrivalTimeEqualWorkPointTime:
    'Час приїзду в депо не може співпадати з часом зупинки',
  invalidRouteDepartureWorkShiftStartPoint:
    'Зміна не може закінчуватися на першій та останній зупинці першого випуску',
  forbiddenToChangeApprovedRouteTimetable:
    'Неможливо редагувати затверджений графік. Створіть новий графік'
};

export const DEFAULT_ERROR_MESSAGE =
  'Не вдалося зберегти, перевірте правильність даних';
