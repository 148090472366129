import { FC } from 'react';
import moment from 'moment';
import { useField } from 'formik';
import { Calendar as CalendarIcon } from 'react-feather';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import DatePicker from 'src/components/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  nativeProps?: Partial<KeyboardDatePickerProps>;
  valueFormat?: string;
  required?: boolean;
  formatDate?: (date: MaterialUiPickersDate) => MaterialUiPickersDate | string;
  fieldError?: boolean;
  disablePast?: boolean;
  disableToday?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
}

const FormDatePicker: FC<Props> = ({
  name: fieldName,
  label,
  placeholder,
  nativeProps,
  valueFormat,
  required,
  formatDate = date => date?.format(valueFormat ?? 'YYYY-MM-DDTHH:mm:ss'),
  fieldError,
  disablePast = false,
  disableToday = false,
  disableFuture = false,
  disabled = false
}) => {
  const [field, { error, touched }, { setValue }] = useField(fieldName);
  const { onChange, ...restNativeProps } = nativeProps ?? {};

  const fieldValue = field.value ?? null;

  return (
    <DatePicker
      disablePast={disablePast}
      disableFuture={disableFuture}
      shouldDisableDate={day =>
        disableToday && moment(day).isSame(moment(), 'day')
      }
      fullWidth
      disableToolbar
      error={fieldError || Boolean(touched && error)}
      helperText={touched && error}
      variant="inline"
      inputVariant="outlined"
      format="DD/MM/yyyy"
      label={label}
      required={required}
      {...field}
      value={fieldValue}
      keyboardIcon={<CalendarIcon />}
      onChange={(date, value) => {
        setValue(formatDate(date));
        onChange && onChange(date, value);
      }}
      placeholder={placeholder}
      {...restNativeProps}
      disabled={disabled}
    />
  );
};

FormDatePicker.defaultProps = {
  placeholder: 'дд/мм/рррр',
  valueFormat: 'YYYY-MM-DDTHH:mm:ss'
};

export default FormDatePicker;
