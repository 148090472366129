import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { ROUTE_PASSPORT } from 'src/config/avlUrls';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';

export function* getTimetable(): SagaIterator {
  const { id, subId } = yield select(selectRouteParams);

  const { data } = yield call(
    apiClient.get,
    `${ROUTE_PASSPORT}/${id}/route-timetables/${subId}`,
    {
      retryAction: actions.getTimetable.request
    }
  );

  yield put(actions.getTimetable.success(data));
}

export function* editTimetable({
  payload
}: ReturnType<typeof actions.editTimetable.request>): SagaIterator {
  const { id, subId } = yield select(selectRouteParams);

  const { data } = yield call(
    apiClient.put,
    `${ROUTE_PASSPORT}/${id}/route-timetables/${subId}`,
    payload,
    {
      retryAction: actions.editTimetable.request
    }
  );

  yield put(
    navigateTo({
      url: `/avl/routes/create/timetable/${id}/details/${data.id}`
    })
  );
  yield put(
    enqueueSnackbar({
      key: 'timetable_update',
      message: 'Графік оновлено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.editTimetable.success());
}

export default function* timetablesEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getTimetable.request),
      safe(getTimetable, actions.getTimetable.failure)
    ),
    takeEvery(
      getType(actions.editTimetable.request),
      safe(editTimetable, actions.editTimetable.failure)
    )
  ]);
}
