import moment from 'moment';
import { DATE_FORMAT_SLASH } from 'src/config';
import { emptyDataPlaceholder } from 'src/utils/text';
import {
  formatPercentageValue,
  formatTableValue
} from 'src/views/clearing/billingDocuments/BillingDetailsListView/utils';
import { ReportFilterData } from '../../types';
import {
  cellStyles,
  headCellStyles,
  reportInfoCellStyles,
  summaryCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';
import { TableRow } from '../types';
import { WorkOfDriverCounteragent, WorkOfDriverReport } from './types';

export const title = '3. Звіт про роботу водіїв';

export const getReportDate = (date: string | undefined) => {
  if (!date) {
    return null;
  }

  return moment(date, 'YYYY-MM-DDTHH:mm:ss').format(DATE_FORMAT_SLASH);
};

export const getCounteragentSection = (
  counteragent: WorkOfDriverCounteragent
): TableRow[] => {
  const result: TableRow[] = [
    [
      {
        colSpan: 20,
        value: counteragent.counteragentFullName,
        styles: summaryCellStyles
      }
    ],
    ...counteragent.rides.map((reportEl, index) => [
      { value: getReportDate(reportEl.date) || index + 1, styles: cellStyles },
      { value: emptyDataPlaceholder(reportEl.driverName), styles: cellStyles },
      { value: emptyDataPlaceholder(reportEl.vehicle), styles: cellStyles },
      { value: emptyDataPlaceholder(reportEl.route), styles: cellStyles },
      {
        value: emptyDataPlaceholder(reportEl.departureTime),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.startTimePlan),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.startTimeActual),
        styles: cellStyles
      },
      { value: emptyDataPlaceholder(reportEl.endTimePlan), styles: cellStyles },
      {
        value: emptyDataPlaceholder(reportEl.endTimeActual),
        styles: cellStyles
      },
      { value: emptyDataPlaceholder(reportEl.arrivalTime), styles: cellStyles },
      {
        value: emptyDataPlaceholder(reportEl.summary.numberOfRides.planned),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.summary.numberOfRides.fact),
        styles: cellStyles
      },
      {
        value: formatPercentageValue(
          (reportEl.summary.numberOfRides.fact /
            reportEl.summary.numberOfRides.planned) *
            100
        ),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.summary.visitedBusStops.planned),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.summary.visitedBusStops.fact),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(
          reportEl.summary.visitedBusStops.onSchedule
        ),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(
          formatPercentageValue(reportEl.summary.attendance)
        ),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.summary.distanceOnRoute.planned),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(reportEl.summary.distanceOnRoute.fact),
        styles: cellStyles
      },
      {
        value: emptyDataPlaceholder(
          formatPercentageValue(reportEl.summary.completeness)
        ),
        styles: cellStyles
      }
    ]),
    [
      {
        value: `Всього по перевізнику ${counteragent.counteragentFullName}`,
        colSpan: 10,
        styles: { ...summaryCellStyles, halign: 'left' }
      },
      {
        value: emptyDataPlaceholder(counteragent.summary.numberOfRides.planned),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(counteragent.summary.numberOfRides.fact),
        styles: summaryCellStyles
      },
      {
        value: formatTableValue(
          (counteragent.summary.numberOfRides.fact /
            counteragent.summary.numberOfRides.planned) *
            100
        ),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(
          counteragent.summary.visitedBusStops.planned
        ),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(counteragent.summary.visitedBusStops.fact),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(
          counteragent.summary.visitedBusStops.onSchedule
        ),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(counteragent.summary.attendance),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(
          counteragent.summary.distanceOnRoute.planned
        ),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(counteragent.summary.distanceOnRoute.fact),
        styles: summaryCellStyles
      },
      {
        value: emptyDataPlaceholder(counteragent.summary.completeness),
        styles: summaryCellStyles
      }
    ]
  ];

  return result;
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: WorkOfDriverReport
): TableConfig => ({
  [tableSemanticElements.TITLE]: [
    [
      {
        colSpan: 20,
        value: title,
        styles: { ...reportInfoCellStyles, halign: 'center' }
      }
    ]
  ],
  [tableSemanticElements.METADATA]: [
    [
      {
        value: 'Дата з',
        styles: reportInfoCellStyles
      },
      {
        colSpan: 19,
        value: filterData.dateFrom,
        styles: reportInfoCellStyles
      }
    ],
    [
      {
        value: 'Дата до',
        styles: reportInfoCellStyles
      },
      {
        colSpan: 19,
        value: filterData.dateTo,
        styles: reportInfoCellStyles
      }
    ],
    []
  ],
  [tableSemanticElements.HEAD]: [
    [
      { rowSpan: 2, value: 'Дата', styles: headCellStyles },
      {
        rowSpan: 2,
        value: 'Водій',
        styles: { ...headCellStyles, cellWidth: 16 }
      },
      {
        rowSpan: 2,
        value: 'Транспортний засіб',
        styles: headCellStyles
      },
      {
        rowSpan: 2,
        value: 'Маршрут',
        styles: { ...headCellStyles, cellWidth: 20 }
      },
      {
        rowSpan: 2,
        value: 'Час виїзду з депо, год',
        styles: headCellStyles
      },
      {
        colSpan: 2,
        value: 'Час початку роботи на маршруті, год',
        styles: headCellStyles
      },
      {
        colSpan: 2,
        value: 'Час закінчення роботи на маршруті, год',
        styles: headCellStyles
      },
      {
        rowSpan: 2,
        value: 'Час заїзду в депо, год',
        styles: headCellStyles
      },
      { colSpan: 3, value: 'Кількість кіл', styles: headCellStyles },
      { colSpan: 4, value: 'Кількість зупинок', styles: headCellStyles },
      { colSpan: 3, value: 'Пробіг, км', styles: headCellStyles }
    ],
    [
      { rowSpan: 1, value: 'план', styles: headCellStyles },
      { rowSpan: 1, value: 'факт', styles: headCellStyles },
      { rowSpan: 1, value: 'план', styles: headCellStyles },
      { rowSpan: 1, value: 'факт', styles: headCellStyles },
      { rowSpan: 1, value: 'план', styles: headCellStyles },
      { rowSpan: 1, value: 'факт', styles: headCellStyles },
      {
        rowSpan: 1,
        value: 'Виконання їздок, %',
        styles: headCellStyles
      },
      { rowSpan: 1, value: 'план', styles: headCellStyles },
      { rowSpan: 1, value: 'факт', styles: headCellStyles },
      { rowSpan: 1, value: 'по графіку', styles: headCellStyles },
      {
        rowSpan: 1,
        value: 'Відвідуваність зупинок, %',
        styles: headCellStyles
      },
      { rowSpan: 1, value: 'план', styles: headCellStyles },
      { rowSpan: 1, value: 'факт', styles: headCellStyles },
      {
        rowSpan: 1,
        value: 'Повнота виконання рейсів, %',
        styles: headCellStyles
      }
    ]
  ],
  [tableSemanticElements.BODY]: report.counteragents
    .map(getCounteragentSection)
    .flat()
});

export const WORK_OF_DRIVER_HEADERS_ROWS_NUMBER = 6;
export const WORK_OF_DRIVER_COLS_NUMBER = 20;
