import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import moment from 'moment';
import {
  NormalizedState,
  Ride,
  RideStatus,
  Topics,
  TrafficStadiumData
} from '../types';
import {
  isRideActiveWithThreshold,
  normalizeSpecialModeDetails
} from '../utils';

const getStadiumIds = (state: RootState) =>
  state.ui.avl.trafficStadium.details.routes.allIds;

export const selectTrafficStadiumsData = createSelector(
  getStadiumIds,
  stadiumIds => stadiumIds.filter(id => !!id) as number[]
);

export const selectTrafficStadiumsAllData = (state: RootState) =>
  state.ui.avl.trafficStadium.details.routes.byId;

export const selectTrafficStadiumDataById = (id: number) => (
  state: RootState
) => state.ui.avl.trafficStadium.details.routes.byId[id];

export const selectTrafficStadiumDataCounteragentId = (state: RootState) =>
  state.ui.avl.trafficStadium.details.counteragentId;

export const selectTrafficStadiumDataCounteragentTreeCode = (
  state: RootState
) => state.ui.avl.trafficStadium.details.counteragentTreeCode;

export const selectVehicleCategoryId = (id: number) =>
  createSelector(
    selectTrafficStadiumDataById(id),
    trafficStadiumData => trafficStadiumData?.traffic.vehicleCategoryId
  );

export const selectRides = (id: number) =>
  createSelector(
    selectTrafficStadiumDataById(id),
    (trafficStadiumData?: TrafficStadiumData) => {
      const rides: Record<RideStatus, Ride[]> = {
        [RideStatus.OnRoute]: [],
        [RideStatus.OffRoute]: [],
        [RideStatus.Depot]: []
      };

      trafficStadiumData &&
        Object.values(trafficStadiumData.traffic.rides.byId).forEach(ride => {
          if (isRideActiveWithThreshold(ride)) {
            const isActive = moment(ride.workingPeriod.to).isAfter(new Date());
            const isTerminated = ride.plannedTermination
              ? moment(ride.plannedTermination.terminatesAtUtc).isBefore(
                  new Date()
                )
              : false;

            rides[ride.status].push({
              ...ride,
              isActive,
              isTerminated
            });
          }
        });

      return rides;
    }
  );

export const selectTurningStopIdx = (id: number) =>
  createSelector(
    selectTrafficStadiumDataById(id),
    (trafficStadiumData: TrafficStadiumData) =>
      trafficStadiumData?.stops.findIndex(
        ({ isTurningPoint }) => isTurningPoint
      )
  );

export const selectSegments = (id: number) =>
  createSelector(
    selectTrafficStadiumDataById(id),
    selectRides(id),
    selectTurningStopIdx(id),
    (
      trafficStadiumData: TrafficStadiumData,
      rides: Record<RideStatus, Ride[]>,
      turningStopIndex: number
    ) => {
      let upperSegmentDistance = 0;
      let lowerSegmentDistance = 0;

      // define starting
      const [startingStop] = trafficStadiumData?.stops;

      // upper stadium line
      const upperSegment = trafficStadiumData?.stops
        .slice(turningStopIndex)
        .map(stop => {
          const { distanceMeters } = trafficStadiumData?.segments.find(
            segment => segment.fromStopId === stop.id
          )!;
          const upperRides = rides.OnRoute.filter(
            ride => ride.fromStopId === stop.id
          ).map(ride => ({
            ...ride,
            distanceMeters: distanceMeters - ride.distanceMeters
          }));
          upperSegmentDistance += distanceMeters;

          return {
            distanceMeters,
            stop: stop.isTurningPoint ? null : stop,
            rides: upperRides
          };
        })
        .reverse();

      // lower stadium line
      const lowerSegment = trafficStadiumData?.stops
        .slice(0, turningStopIndex + 1)
        .map((stop, index) => {
          const { distanceMeters } = trafficStadiumData?.segments.find(
            segment => segment.toStopId === stop.id
          )!;
          const lowerRides = stop.isTurningPoint
            ? []
            : rides.OnRoute.filter(ride => ride.fromStopId === stop.id);
          lowerSegmentDistance += distanceMeters;

          return {
            distanceMeters,
            // either turning stop or the first stop of the route should leave an empty space
            // to display vehicles on those stops in an empty space at the edge of the stadium
            stop: stop.isTurningPoint || index === 0 ? null : stop,
            rides: lowerRides
          };
        });

      return {
        turningStop: trafficStadiumData?.stops[turningStopIndex],
        startingStop,
        upperSegment,
        lowerSegment,
        upperSegmentDistance,
        lowerSegmentDistance
      };
    }
  );

export const selectRouteRideMode = (id: number) =>
  createSelector(
    selectTrafficStadiumDataById(id),
    (trafficStadiumData?: TrafficStadiumData) => ({
      ...trafficStadiumData?.traffic.rideMode,
      specialModeDetails: normalizeSpecialModeDetails(
        trafficStadiumData?.traffic.rideMode.specialModeDetails
      )
    })
  );

export const selectRideSpecialMode = (routeId: number, rideId: number) =>
  createSelector(
    selectTrafficStadiumDataById(routeId),
    (trafficStadiumData: { traffic: { rides: NormalizedState<Ride> } }) => {
      const ride = trafficStadiumData?.traffic.rides.byId[rideId];
      return ride && isRideActiveWithThreshold(ride)
        ? ride.rideMode
        : undefined;
    }
  );

const selectTopicsCombiner = ({ stops, id: routeId }: TrafficStadiumData) => {
  let topics: string[] = [];

  if (routeId) {
    topics = [
      `avl/events/${Topics.RidePositionChangedEvent}/routes/${routeId}/vehicles/+/counteragents/#`,
      `avl/events/${Topics.RouteSpecialModeEvent}/routes/${routeId}`,
      `avl/events/${Topics.CancelRouteSpecialModeEvent}/routes/${routeId}`,
      `avl/events/${Topics.TerminateDepartureEvent}/routes/${routeId}`,
      `avl/events/${Topics.CancelTerminateDepartureEvent}/routes/${routeId}`,
      `avl/events/${Topics.RideSpecialModeEvent}/routes/${routeId}`,
      `avl/events/${Topics.CancelRideSpecialModeEvent}/routes/${routeId}`,
      `avl/events/${Topics.RideChangeoverEvent}/routes/${routeId}`,
      `avl/events/${Topics.CancelRideChangeoverEvent}/routes/${routeId}`,
      `avl/events/${Topics.RouteRideScheduleChangedEvent}/routes/${routeId}`
    ];

    stops.forEach(stop => {
      topics.push(
        `avl/events/${Topics.BusStopUnavailableEvent}/stops/${stop.id}`
      );
      topics.push(
        `avl/events/${Topics.CancelBusStopUnavailableEvent}/stops/${stop.id}`
      );
    });
  }

  return topics.length ? topics : '';
};

export const selectTopics = (id: number) =>
  createSelector(selectTrafficStadiumDataById(id), selectTopicsCombiner);

export const selectRidesOnRouteCount = (id: number) =>
  createSelector(selectRides(id), rides => rides[RideStatus.OnRoute].length);

export const selectAllTopics = createSelector(
  selectTrafficStadiumsAllData,
  (trafficStadiumsData: { [key: string]: TrafficStadiumData }) =>
    Object.values(trafficStadiumsData).reduce((accumulator, item) => {
      const topics = selectTopicsCombiner(item) || [];

      return accumulator.concat(topics);
    }, [] as string[])
);

export const selectTerminationLoading = (state: RootState) =>
  get(state, 'loading.@TRAFFIC_STADIUM/UPDATE_TERMINATE_DEPARTURE');
export const selectChangeoverLoading = (state: RootState) =>
  get(state, 'loading.@TRAFFIC_STADIUM/UPDATE_RIDE_CHANGEOVER');
export const selectStopUnavailabilityLoading = (state: RootState) =>
  get(state, 'loading.@TRAFFIC_STADIUM/UPDATE_STOP_UNAVAILABILITY');
export const selectRouteSpecialModeLoading = (state: RootState) =>
  get(state, 'loading.@TRAFFIC_STADIUM/UPDATE_ROUTE_RIDE_MODE');
export const selectRideSpecialModeLoading = (state: RootState) =>
  get(state, 'loading.@TRAFFIC_STADIUM/UPDATE_RIDE_SPECIAL_MODE');

export const selectGpsLoading = (id: number) => (state: RootState) =>
  state.ui.avl.trafficStadium.details.routes.byId[id]?.gpsLoading;

export const selectCurrentCounterAgentId = (state: RootState) =>
  state.ui.avl.trafficStadium.details.counteragentId;
